<template>
  <div>
    <div v-if="skeletonLoading">
      <v-row>
        <v-col cols="12" md="12">
          <v-skeleton-loader
            v-bind="attrs"
            min-height="600px"
            type="article, article, article, actions"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <div v-if="!skeletonLoading">
      <v-card class="mt-6" outlined>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
              <v-subheader class="text-h6 black--text">
                Datos Generales
              </v-subheader>
            </v-col>
          </v-row>
          <v-form
            ref="form"
            v-on:submit.prevent="registrarItem"
            v-model="validForm"
          >
            <v-row>
              <!--inicio:: campo -->
              <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                <v-select
                  dense
                  filled
                  v-model="datosItem.tipoIniciativaId"
                  :items="tiposIniciativa"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Tipo de iniciativa"
                  item-text="tipo"
                  item-value="id"
                  :rules="[selectRequired('tipo de iniciativa')]"
                  menu-props="auto"
                >
                </v-select>
              </v-col>
              <!--fin:: campo-->

              <!--inicio:: campo -->
              <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                <v-text-field
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  color="blue-grey lighten-2"
                  v-model="datosItem.nombreIniciativa"
                  label="Nombre de la iniciativa"
                  :rules="[
                    required('Nombre de la iniciativa'),
                    minLength('Nombre de la iniciativa', 5),
                    maxLength('Nombre de la iniciativa', 200),
                  ]"
                  maxlength="200"
                ></v-text-field>
              </v-col>
              <!--fin:: campo-->
            </v-row>

            <v-row>
              <!--inicio:: campo -->
              <v-col cols="12" md="8" sm="6" xs="12" class="pt-0 pb-0">
                <template>
                  <v-file-input
                    id="respaldoFile"
                    dense
                    filled
                    placeholder="Seleccionar documento"
                    label="Documento respaldo de la iniciativa"
                    append-icon="mdi-file-pdf-outline"
                    prepend-icon=""
                    accept="application/pdf,image/jpeg"
                    @change="onFileChange"
                    :rules="[
                      accion === 1
                        ? fileRequired('Documento respaldo iniciativa')
                        : true,
                    ]"
                    :show-size="1000"
                    ref="respaldoFile"
                    :class="accion === 1 ? `required` : ``"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip v-if="index < 2" small label color="primary">
                        {{ text }}
                      </v-chip>
                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} archivo(s)
                      </span>
                    </template>
                  </v-file-input>
                </template>
              </v-col>
              <!--fin:: campo-->

              <v-col
                cols="12"
                md="4"
                sm="6"
                xs="12"
                class="pt-0"
                v-if="accion === 2"
              >
                <v-btn
                  class="white--text ma-2 mt-1 mb-0 ml-0"
                  medium
                  tile
                  color="light-blue-502"
                  @click="descargarArchivo(datosItem.pathDocumentoIniciativa)"
                >
                  <v-icon left>mdi-cloud-download</v-icon> Descargar respaldo
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <!--inicio:: campo -->
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-select
                  dense
                  filled
                  v-model="datosItem.modalidadFinanciamientoId"
                  :items="modalidadesFinanciamiento"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Modalidad de financiamiento"
                  item-text="financiamiento"
                  item-value="id"
                  :rules="[selectRequired('modalidad de financiamiento')]"
                  menu-props="auto"
                >
                </v-select>
              </v-col>
              <!--fin:: campo-->
            </v-row>

            <v-row>
              <!--inicio:: campo -->
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-textarea
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  color="blue-grey lighten-2"
                  v-model="datosItem.objetivo"
                  label="Objetivo"
                  rows="3"
                  :rules="[
                    required('objetivo'),
                    minLength('objetivo', 5),
                    maxLength('objetivo', 200),
                  ]"
                  maxlength="200"
                ></v-textarea>
              </v-col>
              <!--fin:: campo-->
            </v-row>
            <v-row>
              <!--inicio:: campo-->
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-select
                  dense
                  filled
                  v-model="datosItem.sectoresBeneficiadosId"
                  :items="sectores"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Seleccionar Sector"
                  item-text="sector"
                  item-value="id"
                  menu-props="auto"
                  :rules="[selectRequired('sector')]"
                >
                </v-select>
              </v-col>
              <!--fin:: campo-->

              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-select
                  dense
                  filled
                  :loading="ddSectorSidLoading"
                  v-model="datosItem.sectorSIDCSSId"
                  :items="sectoresSidcss"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Sector SIDCSS "
                  item-text="sector"
                  item-value="id"
                  menu-props="auto"
                  :rules="[selectRequired('sector SIDCSS')]"
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-select
                  dense
                  filled
                  :loading="ddEstadosNegociacionLoading"
                  v-model="datosItem.estadosIniciativaId"
                  :items="estadosNegocioacion"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Estado "
                  item-text="estado"
                  item-value="id"
                  menu-props="auto"
                  :rules="[selectRequired('estado')]"
                >
                </v-select>
              </v-col>
              <!--fin:: campo-->
            </v-row>

            <v-row class="mt-4">
              <v-col cols="12" md="12" sm="12">
                <v-spacer></v-spacer>
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  :elevation="0"
                  :disabled="!validForm"
                  :loading="btnRegistroLoading"
                >
                  Guardar información
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>

      <!-- fin:: instituciones nacionales -->
      <v-card class="mt-6" outlined v-if="accion === 2">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
              <v-subheader class="text-h6 black--text">
                Instituciones que participan por Guatemala
              </v-subheader>
            </v-col>
          </v-row>
          <v-form
            ref="form"
            v-on:submit.prevent="registrarInstitucionNacional"
            v-model="validFormInstituciones"
          >
            <v-row class="mt-6">
              <!--inicio:: campo-->
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-autocomplete
                  v-model="institucionSeleccionada"
                  :items="instituciones"
                  :loading="institucionesLoading"
                  dense
                  filled
                  class="required"
                  label="Institución"
                  :item-text="
                    (item) => item.nombreInstitucion + ' (' + item.sigla + ')'
                  "
                  item-value="id"
                  return-object
                  :rules="[selectRequired('institución')]"
                  :no-data-text="
                    instituciones != null
                      ? 'Selecciona una institución'
                      : 'No se han encontrado instituciones'
                  "
                  menu-props="auto"
                >
                </v-autocomplete>
              </v-col>
              <!--fin:: campo-->

              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-spacer></v-spacer>
                <v-btn
                  class="ma-0 white--text"
                  medium
                  type="submit"
                  color="light-blue darken-2"
                  :disabled="!validFormInstituciones"
                  :loading="btnRegistroInstitucionesNacionalesLoading"
                >
                  <v-icon left>mdi-plus</v-icon> Agregar institución
                </v-btn>
              </v-col>

              <v-col cols="12" md="12" sm="12">
                <template>
                  <v-data-table
                    :headers="headersInstituciones"
                    :items="institucionesNacionales"
                    :items-per-page="5"
                    :loading="tableInstitucionesNacionalesLoading"
                    :header-props="{
                      sortByText: 'Ordenar por',
                    }"
                    no-data-text="No se encontraron registros"
                    loading-text="Cargando..."
                    :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-page-first',
                      lastIcon: 'mdi-page-last',
                      prevIcon: 'mdi-chevron-left',
                      nextIcon: 'mdi-chevron-right',
                      'items-per-page-text': 'Registros por página',
                      pageText: '{0}-{1} de {2}',
                    }"
                    class="elevation-1"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.nombreInstitucion }}</td>
                        <!--<td>
                      <v-chip
                        class="ma-2 font-weight-medium"
                        label
                        :color="
                          item.estadoId === 1
                            ? 'blue-grey lighten-4'
                            : item.estadoId === 2
                            ? 'blue lighten-4'
                            : item.estadoId === 6
                            ? 'cyan lighten-4'
                            : item.estadoId === 7
                            ? 'deep-orange lighten-4'
                            : 'pink lighten-4'
                        "
                        :text-color="
                          item.estadoId === 1
                            ? 'blue-grey lighten-1'
                            : item.estadoId === 2
                            ? 'blue lighten-1'
                            : item.estadoId === 6
                            ? 'cyan darken-1'
                            : item.estadoId === 7
                            ? 'deep-orange lighten-1'
                            : 'pink darken-1'
                        "
                        small
                      >
                        {{ item.estado }}
                      </v-chip>
                    </td>-->
                        <td>
                          <v-btn
                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                            small
                            depressed
                            color="blue-grey lighten-5"
                            @click="eliminarInstitucionNacional(item.id)"
                          >
                            <v-icon left>mdi-delete</v-icon> Eliminar
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </template>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>

      <!-- fin:: instituciones nacionales -->

      <!-- inicio:: instituciones extranjeras -->
      <v-card class="mt-6" outlined v-if="accion === 2">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
              <v-subheader class="text-h6 black--text">
                Instituciones extranjeras
              </v-subheader>
            </v-col>
          </v-row>
          <v-form
            ref="form"
            v-on:submit.prevent="registrarInstitucionesExtranjeras"
            v-model="validFormInstitucionesExtranjeras"
          >
            <v-row class="mt-6">
              <!--inicio:: campo-->
              <v-col cols="12" md="12" sm="12">
                <v-textarea
                  v-model="datosInstitucionExtranjera.instituciones"
                  dense
                  filled
                  label="Ingrese instituciones extranjeras"
                  :rules="[
                    required('Instituciones otro país'),
                    minLength('Instituciones otro país', 5),
                    maxLength('Instituciones otro país', 2000),
                  ]"
                ></v-textarea>
              </v-col>
              <!--fin:: campo-->

              <v-col cols="12" md="12" sm="12" class="pt-0 pb-2">
                <v-spacer></v-spacer>
                <v-btn
                  class="ma-0 white--text float-right"
                  medium
                  type="submit"
                  color="light-blue darken-2"
                  :disabled="!validFormInstitucionesExtranjeras"
                  :loading="btnRegistroInstitucionExtranjeraLoading"
                >
                  <v-icon left>mdi-plus</v-icon> Guardar instutuciones
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>

      <!-- fin:: instituciones extranjeras -->

      <!-- inicio:: roles y actores-->
      <v-card class="mt-6 d-none" outlined v-if="accion === 2">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
              <v-subheader class="text-h6 black--text">
                Actores y Roles
              </v-subheader>
            </v-col>
          </v-row>
          <v-form
            ref="form"
            v-on:submit.prevent="registrarActor"
            v-model="validFormActores"
          >
            <v-row class="mt-6">
              <!--inicio:: campo-->
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-select
                  dense
                  filled
                  v-model="datosRolActor.rolesActorId"
                  :items="roles"
                  color="blue-grey lighten-2"
                  class="required"
                  label="Seleccionar Rol"
                  item-text="nombre"
                  item-value="id"
                  :rules="[selectRequired('País')]"
                  menu-props="auto"
                >
                </v-select>
              </v-col>
              <!--fin:: campo-->

              <!--inicio:: campo-->
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-select
                  dense
                  filled
                  v-model="datosRolActor.paisId"
                  :items="paises"
                  :loading="ddPaisesLoading"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Pais"
                  item-text="nombre"
                  item-value="id"
                  :rules="[selectRequired('País')]"
                  menu-props="auto"
                >
                </v-select>
              </v-col>
              <!--fin:: campo-->

              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-spacer></v-spacer>
                <v-btn
                  class="ma-0 white--text"
                  medium
                  type="submit"
                  color="light-blue darken-2"
                  :disabled="!validFormActores"
                  :loading="btnRegistroRolActorLoading"
                >
                  <v-icon left>mdi-plus</v-icon> Agregar
                </v-btn>
              </v-col>

              <v-col cols="12" md="12" sm="12">
                <template>
                  <v-data-table
                    :headers="headersActores"
                    :items="rolesActores"
                    :items-per-page="5"
                    :loading="tableRolesActoresLoading"
                    :header-props="{
                      sortByText: 'Ordenar por',
                    }"
                    no-data-text="No se encontraron registros"
                    loading-text="Cargando..."
                    :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-page-first',
                      lastIcon: 'mdi-page-last',
                      prevIcon: 'mdi-chevron-left',
                      nextIcon: 'mdi-chevron-right',
                      'items-per-page-text': 'Registros por página',
                      pageText: '{0}-{1} de {2}',
                    }"
                    class="elevation-1"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.nombre }}</td>
                        <td>{{ item.pais }}</td>
                        <!--<td>
                      <v-chip
                        class="ma-2 font-weight-medium"
                        label
                        :color="
                          item.estadoId === 1
                            ? 'blue-grey lighten-4'
                            : item.estadoId === 2
                            ? 'blue lighten-4'
                            : item.estadoId === 6
                            ? 'cyan lighten-4'
                            : item.estadoId === 7
                            ? 'deep-orange lighten-4'
                            : 'pink lighten-4'
                        "
                        :text-color="
                          item.estadoId === 1
                            ? 'blue-grey lighten-1'
                            : item.estadoId === 2
                            ? 'blue lighten-1'
                            : item.estadoId === 6
                            ? 'cyan darken-1'
                            : item.estadoId === 7
                            ? 'deep-orange lighten-1'
                            : 'pink darken-1'
                        "
                        small
                      >
                        {{ item.estado }}
                      </v-chip>
                    </td>-->
                        <td>
                          <v-btn
                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                            small
                            depressed
                            color="blue-grey lighten-5"
                            @click="eliminarRolActor(item.id)"
                          >
                            <v-icon left>mdi-delete</v-icon> Eliminar
                          </v-btn>

                          <v-btn
                            v-if="item.estadoId === 1"
                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                            small
                            depressed
                            color="blue-grey lighten-5"
                            @click="mostrarFormularioProductos(item.id)"
                          >
                            <v-icon left>mdi-plus</v-icon> Agregar Productos
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </template>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>

      <!-- fin:: roles y actores -->

      <v-card class="mt-6" outlined v-if="accion === 2">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
              <v-subheader class="text-h6 black--text"> Cobertura </v-subheader>
            </v-col>
          </v-row>
          <v-form
            ref="form"
            v-on:submit.prevent="registrarCobertura"
            v-model="validFormCobertura"
          >
            <v-row class="mt-6">
              <!--inicio:: campo-->
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-select
                  dense
                  filled
                  v-model="datosCobertura.departamentosId"
                  :items="departamentos"
                  color="blue-grey lighten-2"
                  label="Seleccionar departamento"
                  item-text="departamento"
                  item-value="id"
                  menu-props="auto"
                  :rules="[selectRequired('departamento')]"
                  @change="obtenerMunicipiosPorDepartamento"
                >
                </v-select>
              </v-col>
              <!--fin:: campo-->

              <!--inicio:: campo-->
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-select
                  dense
                  filled
                  :loading="ddMunicipiosLoading"
                  v-model="datosCobertura.municipiosId"
                  :items="municipios"
                  color="blue-grey lighten-2"
                  label="Seleccionar Municipio"
                  item-text="municipio"
                  item-value="id"
                  :rules="[selectRequired('municipio')]"
                  menu-props="auto"
                >
                </v-select>
              </v-col>
              <!--fin:: campo-->
            </v-row>

            <v-row>
              <!--inicio:: campo-->
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-select
                  dense
                  filled
                  v-model="areaSeleccionada"
                  :items="areas"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Seleccionar Área"
                  item-text="nombreArea"
                  item-value="id"
                  return-object
                  menu-props="auto"
                  :rules="[selectRequired('area')]"
                >
                </v-select>
              </v-col>
              <!--fin:: campo-->
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-spacer></v-spacer>
                <v-btn
                  class="ma-0 white--text"
                  medium
                  type="submit"
                  color="light-blue darken-2"
                  :disabled="!validFormCobertura"
                  :loading="btnRegistroCoberturaLoading"
                >
                  <v-icon left>mdi-plus</v-icon> Agregar Cobertura
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12">
                <template>
                  <v-data-table
                    :headers="headersCobertura"
                    :items="coberturas"
                    :items-per-page="5"
                    :loading="tableCoberturasLoading"
                    :header-props="{
                      sortByText: 'Ordenar por',
                    }"
                    no-data-text="No se encontraron registros"
                    loading-text="Cargando..."
                    :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-page-first',
                      lastIcon: 'mdi-page-last',
                      prevIcon: 'mdi-chevron-left',
                      nextIcon: 'mdi-chevron-right',
                      'items-per-page-text': 'Registros por página',
                      pageText: '{0}-{1} de {2}',
                    }"
                    class="elevation-1"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.departamento }}</td>
                        <td>{{ item.municipio }}</td>
                        <td>{{ item.area }}</td>
                        <!--<td>
                      <v-chip
                        class="ma-2 font-weight-medium"
                        label
                        :color="
                          item.estadoId === 1
                            ? 'blue-grey lighten-4'
                            : item.estadoId === 2
                            ? 'blue lighten-4'
                            : item.estadoId === 6
                            ? 'cyan lighten-4'
                            : item.estadoId === 7
                            ? 'deep-orange lighten-4'
                            : 'pink lighten-4'
                        "
                        :text-color="
                          item.estadoId === 1
                            ? 'blue-grey lighten-1'
                            : item.estadoId === 2
                            ? 'blue lighten-1'
                            : item.estadoId === 6
                            ? 'cyan darken-1'
                            : item.estadoId === 7
                            ? 'deep-orange lighten-1'
                            : 'pink darken-1'
                        "
                        small
                      >
                        {{ item.estado }}
                      </v-chip>
                    </td>-->
                        <td>
                          <v-btn
                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                            small
                            depressed
                            color="blue-grey lighten-5"
                            @click="eliminarCobertura(item.id)"
                          >
                            <v-icon left>mdi-delete</v-icon> Eliminar
                          </v-btn>

                          <v-btn
                            v-if="item.estadoId === 1"
                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                            small
                            depressed
                            color="blue-grey lighten-5"
                            @click="mostrarFormularioProductos(item.id)"
                          >
                            <v-icon left>mdi-plus</v-icon> Agregar Productos
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </template>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>

      <!--Inicio:: Snack alert-->
      <SnackAlert ref="snackalert"></SnackAlert>
      <!-- Fin:: Snack alert-->
    </div>
  </div>
</template>

<script>
//import moment from "moment";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DownloadFile from "@/core/untils/downloadfile.js";
import validations from "@/core/untils/validations.js";
import { OBTENER_PAISES } from "@/core/services/store/catalogos/paises/pais.module";
import {
  OBTENER_ITEMS_CATALOGO,
  OBTENER_SUBITEMS_CATALOGO,
} from "@/core/services/store/catalogos/catalogointerno.module";
import { CARGAR_ARCHIVO } from "@/core/services/store/cargaarchivo.module";
import {
  REGISTRAR_INICIATIVA,
  ACTUALIZAR_INICIATIVA,
  OBTENER_INICIATIVA,
  REGISTRAR_INSTITUCIONES_ESTRANJERAS_CSS,
} from "@/core/services/store/negociaciones/iniciativadirecta.module";
import {
  OBTENER_INSTITUCIONES_NACIONALES_CSS,
  REGISTRAR_INSTITUCION_NACIONAL_CSS,
  ELIMINAR_INSTITUCION_NACIONAL_CSS,
} from "@/core/services/store/negociaciones/iniciativas/instituciones/asignacioninstitucion.module";

import {
  OBTENER_ROLES_ACTORES_CSS,
  REGISTRAR_ROL_ACTOR_CSS,
  ELIMINAR_ROL_ACTOR_CSS,
} from "@/core/services/store/negociaciones/iniciativas/rolesactores/asignacionrolactor.module";
import {
  OBTENER_COBERTURAS_CSS,
  REGISTRAR_COBERTURA_CSS,
  ELIMINAR_COBERTURA_CSS,
} from "@/core/services/store/negociaciones/iniciativas/cobertura/cobertura.module";
//import { OBTENER_INSTITUCIONES} from "@/core/services/store/instituciones/institucion.module";
export default {
  name: "RegistroDirectaDatosGenerales",
  props: ["iniciativaId", "negociacionId", "accion", "nombreNegociacion"],
  components: {
    SnackAlert,
  },
  data() {
    return {
      skeletonLoading: false,
      validForm: false,
      validFormActores: false,
      validFormInstituciones: false,
      validFormCobertura: false,
      //institucionSeleccionada
      validFormInstitucionesExtranjeras: false,
      ddInstitucionesLoading: false,
      ddMunicipiosLoading: false,
      btnRegistroLoading: false,
      btnRegistroInstitucionExtranjeraLoading: false,
      btnRegistroRolActorLoading: false,
      btnRegistroCoberturaLoading: false,
      tableCoberturasLoading: false,
      btnRegistroInstitucionesNacionalesLoading: false,
      institucionesNacionales: [],
      tableInstitucionesNacionalesLoading: false,
      tableRolesActoresLoading: false,
      archivoCargado: false,
      tiposIniciativa: [],
      modalidadesFinanciamiento: [],
      roles: [],
      instituciones: [],
      actores: [{ rol: 0, institucion: "" }],
      rolSeleccionado: 0,
      institucionSeleccionada: {},
      institucionesExtranjeras: [],
      institucionesExtranjerasSeleccionadas: [],
      sectores: [],
      areas: [],
      estadosIniciativa: [],
      areaSeleccionada: {},
      cobertura: [],
      departamentos: [],
      departamentoSeleccionado: {},
      municipios: [],
      municipioSeleccionado: [],
      documentoMecanismo: [],
      // negociacionId: 0,
      rolesActores: [],
      datosItem: {
        tipoIniciativaId: 0,
        nombreIniciativa: "",
        objetivo: "",
        pathDocumentoIniciativa: "",
        modalidadFinanciamientoId: 0,
        sectoresBeneficiadosId: 0,
        //institucionesOtroPais: "",
        estadosId: 1,
        usuarioCreacion: "admin",
        negociacionId: this.negociacionId,
        estadosIniciativaId: 1,
        sectorSIDCSSId: 0,
      },

      sectoresSidcss: [],
      ddSectorSidLoading: false,
      archivoReemplazado: false,
      datosInstitucion: {
        institucionesId: 0,
        estadosId: 1,
        usuarioCreacion: "admin",
        iniciativaId: this.iniciativaId,
      },
      datosInstitucionExtranjera: {
        instituciones: "",
      },
      datosRolActor: {
        rolesActorId: 0,
        paisId: 0,
        estadosId: 1,
        usuarioCreacion: "admin",
        iniciativaId: this.iniciativaId,
      },
      datosCobertura: {
        municipiosId: 0,
        departamentosId: 0,
        iniciativaId: 0,
        estadosId: 1,
        area: "",
        usuarioCreacion: "admin",
      },
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 0,
      },
      estadosNegocioacion: [],
      ddEstadosNegociacionLoading: false,
      ...validations,
    };
  },

  methods: {
    onFileChange(e) {
      if (e) {
        //!?Validar que el tipo de archivo sea pdf
        if (e.type != "application/pdf" && e.type != "image/jpeg") {
          // Api call
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El archivo que desea ingresar no es válido.`
          );
          //console.log("no es un pdf..")
          //this.$refs.file.reset();
          document.querySelector("#respaldoFile").value = "";
          this.$refs.respaldoFile.reset();
          return false;
        }
        //?Validar que el tamaó del archivo no exceda los 10Mb
        if (e.size / 1024 / 1024 > 10) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El tamaño del archivo excede el límite permitido (10Mb)`
          );
          // this.$refs.file.reset();
          document.querySelector("#respaldoFile").value = "";
          this.$refs.respaldoFile.reset();
          return false;
        }
        if (this.accion === 2) {
          this.archivoReemplazado = true;
        }
        this.documentoMecanismo = e;
      }
    },

    resetItems(tipo) {
      this.skeletonLoading = true;
      document.querySelector("#respaldoFile").value = "";
      this.$refs.respaldoFile.reset();

      this.datosItem = {
        tipoIniciativaId: 0,
        nombreIniciativa: "",
        objetivo: "",
        pathDocumentoIniciativa: "",
        modalidadFinanciamientoId: 0,
        sectoresBeneficiadosId: 0,
        institucionesOtroPais: "",
        estadosId: 1,
        usuarioCreacion: "admin",
        negociacionId: this.negociacionId,
        estadosIniciativaId: 1,
        sectorSIDCSSId: 0,
      };

      this.datosInstitucion.institucionesId = 0;
      this.institucionesNacionales = [];
      this.datosInstitucionExtranjera.instituciones = "";
      //this.datosInstitucionExtranjera.instituciones="";
      this.institucionesOtroPais = [];

      this.datosItem.nombreIniciativa = this.nombreNegociacion;

      this.areaSeleccionada = {};

      if (tipo === 2) {
        this.skeletonLoading = false;
      }
    },

    async obtenerPaises() {
      this.paises = [];
      this.ddPaisesLoading = true;

      this.$store
        .dispatch(OBTENER_PAISES)
        .then(() => {
          this.paises = this.$store.state.pais.paises;
          //console.log(this.paises);
          //console.log(this.items)
          this.ddPaisesLoading = false;
        })
        .catch(() => {
          this.ddPaisesLoading = false;
        });
    },

    async obtenerTiposIniciativa() {
      this.tiposIniciativa = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "TipoIniciativa/all/1" })
        .then((res) => {
          if (res.status === 200) {
            this.tiposIniciativa = res.data;
          }
        })
        .catch(() => {
          this.tiposIniciativa = [];
          //this.skeletonLoading=false;
        });
    },

    async obtenerModalidadesFinanciamiento() {
      this.modalidadesFinanciamiento = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "ModalidadFinanciamiento/all/1",
        })
        .then((res) => {
          if (res.status === 200) {
            this.modalidadesFinanciamiento = res.data;
          }
        })
        .catch(() => {
          this.modalidadesFinanciamiento = [];
        });
    },

    async obtenerSectoresSisCss() {
      this.ddSectorSidLoading = true;

      this.sectoresSidcss = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "SectorSIDCSS" })
        .then((res) => {
          if (res.status === 200) {
            this.sectoresSidcss = res.data;
          }

          this.ddSectorSidLoading = false;
        })
        .catch(() => {
          this.sectoresSidcss = [];
          this.ddSectorSidLoading = false;
        });
    },

    async obtenerEstadosIniciativa() {
      this.estadosIniciativa = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "EstadosIniciativa/all/1",
        })
        .then((res) => {
          if (res.status === 200) {
            this.estadosIniciativa = res.data;
          }
        })
        .catch(() => {
          this.estadosIniciativa = [];
        });
    },

    async obtenerEstadosNegociacion() {
      this.ddEstadosNegociacionLoading = true;
      this.estadosNegocioacion = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "EstadosIniciativa/all/1",
        })
        .then((res) => {
          if (res.status === 200) {
            this.estadosNegocioacion = res.data;
          }
          this.ddEstadosNegociacionLoading = false;
        })
        .catch(() => {
          this.estadosNegocioacion = [];
          this.ddEstadosNegociacionLoading = false;
        });
    },

    async obtenerRoles() {
      /*this.roles = [
        { id: 1, nombreRol: "Rol 1" },
        { id: 2, nombreRol: "Rol 2" },
        { id: 3, nombreRol: "Rol 3" }
      ];*/

      this.roles = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "RolesActores/all/1" })
        .then((res) => {
          if (res.status === 200) {
            this.roles = res.data;
          }
        })
        .catch(() => {
          this.roles = [];
        });
    },

    async obtenerInstituciones() {
      this.ddInstitucionesLoading = true;

      this.instituciones = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `Entidades/all/1` })
        .then((res) => {
          if (res.status == 200) {
            this.instituciones = res.data;
          }
          this.ddInstitucionesLoading = false;
        })
        .catch(() => {
          this.ddInstitucionesLoading = false;
          this.instituciones = [];
        });
    },

    async obtenerInstitucionesExtranjeras() {
      this.institucionesExtranjeras = [
        { id: 1, nombreInstitucion: "Institucion Extranjera 1" },
        { id: 2, nombreInstitucion: "Institucion Extranjera 2" },
        { id: 3, nombreInstitucion: "Institucion Extranjera 3" },
      ];
    },

    async obtenerSectores() {
      this.sectores = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Sectores/all/1" })
        .then((res) => {
          if (res.status === 200) {
            this.sectores = res.data;
          }
        })
        .catch(() => {
          this.sectores = [];
        });
    },

    async obtenerAreas() {
      this.areas = [
        { id: 1, nombreArea: "Rural" },
        { id: 2, nombreArea: "Urbana" },
      ];
    },

    async obtenerDepartamentos() {
      this.departamentos = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "Departamentos/all/1/94",
        })
        .then((res) => {
          if (res.status === 200) {
            this.departamentos = res.data;
          }
        })
        .catch(() => {
          this.departamentos = [];
        });
    },

    async obtenerMunicipiosPorDepartamento() {
      this.municipios = [];
      this.ddMunicipiosLoading = true;
      await this.$store
        .dispatch(OBTENER_SUBITEMS_CATALOGO, {
          id: this.datosCobertura.departamentosId,
          endpoint: "Municipios/all/1",
        })
        .then((res) => {
          if (res.status === 200) {
            this.municipios = res.data;
          }
          this.ddMunicipiosLoading = false;
        })
        .catch(() => {
          this.municipios = [];
          this.ddMunicipiosLoading = false;
        });
    },

    //Obtener los datos generales de la iniciativa
    async obtenerIniciativa(idIniciativa) {
      this.datosItem = [];
      this.datosInstitucionExtranjera.instituciones = "";
      this.dialogLoaderVisible = true;

      this.$store
        .dispatch(OBTENER_INICIATIVA, idIniciativa)
        .then((res) => {
          if (res.status === 200) {
            this.datosItem = res.data;
            this.datosItem.area === "Rural"
              ? (this.areaSeleccionada = {
                  id: 1,
                  nombreArea: this.datosItem.area,
                })
              : (this.areaSeleccionada = {
                  id: 2,
                  nombreArea: this.datosItem.area,
                });
            this.datosInstitucionExtranjera.instituciones =
              this.datosItem.institucionesOtroPais;
          }
          this.skeletonLoading = false;
          this.dialogLoaderVisible = false;
        })
        .catch(() => {
          this.skeletonLoading = false;
          this.dialogLoaderVisible = false;
        });
    },

    //Obtener las instituciones registradas nacionales
    async obtenerInstitucionesNacionales() {
      this.institucionesNacionales = [];
      this.tableInstitucionesNacionalesLoading = true;

      this.$store
        .dispatch(OBTENER_INSTITUCIONES_NACIONALES_CSS, {
          id: this.iniciativaId,
        })
        .then((res) => {
          //  console.log(res)
          if (res.status === 200) {
            this.institucionesNacionales = res.data;
            //this.datosItem.area === "Rural"  ? this.areaSeleccionada = {id: 1, nombreArea: this.datosItem.area}  : this.areaSeleccionada = {id: 2, nombreArea: this.datosItem.area};
          }
          this.tableInstitucionesNacionalesLoading = false;
        })
        .catch(() => {
          this.tableInstitucionesNacionalesLoading = false;
        });
    },

    //Registrar las instituciones que participan por Guatemala
    async registrarInstitucionNacional() {
      if (!this.institucionSeleccionada) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `Debe seleccionar una institución para poder continuar con el registro`
        );
        return;
      }

      this.datosInstitucion.ENTIDAD_ID = this.institucionSeleccionada.id;
      this.datosInstitucion.institucionesId = this.institucionSeleccionada.id;

      this.btnRegistroInstitucionesNacionalesLoading = true;
      this.datosInstitucion.iniciativaId = this.iniciativaId;
      await this.$store
        .dispatch(REGISTRAR_INSTITUCION_NACIONAL_CSS, {
          datos: this.datosInstitucion,
        })
        .then((res) => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.institucionSeleccionada = {};
            //  this.switchItemEstado = true;
            //  this.datosIndicador.detalleIndicador = '';
            this.btnRegistroInstitucionesNacionalesLoading = false;
            this.obtenerInstitucionesNacionales();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroInstitucionesNacionalesLoading = false;
        })
        .catch((error) => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnRegistroInstitucionesNacionalesLoading = false;
        });
    },

    //Eliminar una institucion nacional
    async eliminarInstitucionNacional(id) {
      this.btnRegistroInstitucionesNacionalesLoading = true;
      this.datosRolActor.iniciativaId = this.iniciativaId;
      await this.$store
        .dispatch(ELIMINAR_INSTITUCION_NACIONAL_CSS, id)
        .then((res) => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            //  this.switchItemEstado = true;
            //  this.datosIndicador.detalleIndicador = '';
            this.btnRegistroInstitucionesNacionalesLoading = false;
            this.obtenerInstitucionesNacionales();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroInstitucionesNacionalesLoading = false;
        })
        .catch((error) => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnRegistroInstitucionesNacionalesLoading = false;
        });
    },

    ///Registrar instituciones extranjeras
    async registrarInstitucionesExtranjeras() {
      this.btnRegistroInstitucionExtranjeraLoading = true;
      this.datosInstitucionExtranjera.iniciativaId = this.iniciativaId;
      await this.$store
        .dispatch(REGISTRAR_INSTITUCIONES_ESTRANJERAS_CSS, {
          id: this.iniciativaId,
          datosItem: this.datosInstitucionExtranjera,
        })
        .then((res) => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            //  this.switchItemEstado = true;
            //  this.datosIndicador.detalleIndicador = '';
            this.btnRegistroInstitucionExtranjeraLoading = false;
            //  this.obtenerInstitucionesNacionales();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroInstitucionExtranjeraLoading = false;
        })
        .catch((error) => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnRegistroInstitucionesNacionalesLoading = false;
        });
    },

    //Obtener las asignaciones de roles y actores de la iniciativa
    async obtenerRolesActores() {
      this.rolesActores = [];
      this.tableRolesActoresLoading = true;

      this.$store
        .dispatch(OBTENER_ROLES_ACTORES_CSS, { id: this.negociacionId })
        .then((res) => {
          //  console.log(res)
          if (res.status === 200) {
            this.rolesActores = res.data;
            //this.datosItem.area === "Rural"  ? this.areaSeleccionada = {id: 1, nombreArea: this.datosItem.area}  : this.areaSeleccionada = {id: 2, nombreArea: this.datosItem.area};
          }
          this.tableRolesActoresLoading = false;
        })
        .catch(() => {
          this.tableRolesActoresLoading = false;
        });
    },

    async registrarActor() {
      let oferentes = 0;
      let receptores = 0;
      let ambos = 0;

      let registro = false;
      //Definir las reglas para el registro de actores y roles

      // Obtener la cantidad de registros de oferentes, receptores y ambos del array de rolesActores
      oferentes = this.rolesActores.filter(
        (item) => item.rolesActorId === 1
      ).length;
      receptores = this.rolesActores.filter(
        (item) => item.rolesActorId === 2
      ).length;
      ambos = this.rolesActores.filter(
        (item) => item.rolesActorId === 3
      ).length;

      // console.log(oferentes + " " + receptores + " " + ambos + "  rolselec " + this.datosRolActor.rolesActorId)

      // 1 Si es bilateral solo se puede seleccionar un oferente(1) y un receptor(2); también 1 sola vez (ambos(3))

      if (this.datosItem.modalidadFinanciamientoId === 1) {
        if (
          this.datosRolActor.rolesActorId === 1 &&
          (oferentes > 0 || ambos > 0)
        ) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            "Solo se puede registrar un oferente para la modalidad bilateral"
          );
          registro = false;
          return;
        }

        //Validar cuando el que se desea registrar es receptor
        if (
          this.datosRolActor.rolesActorId === 2 &&
          (receptores > 0 || ambos > 0)
        ) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            "Solo se puede registrar un oferente para la modalidad bilateral"
          );
          registro = false;
          return;
        }

        //Validar cuando el que se desea registrar es ambos
        if (
          this.datosRolActor.rolesActorId === 3 &&
          (oferentes > 0 || receptores > 0 || ambos > 0)
        ) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            "Solo se puede registrar un oferente para la modalidad bilateral"
          );
          registro = false;
          return;
        }
      }

      // 2 Si es triangular solo se puede seleccionar dos oferentes(1), un receptor(2) y ningun (ambos(3))

      if (this.datosItem.modalidadFinanciamientoId === 2) {
        //Validar cuando el que se desea registrar es oferente
        let contadorPais = this.rolesActores.filter(
          (item) => item.paisId === this.datosRolActor.paisId
        ).length;

        if (contadorPais > 0) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            "Ya se encuentra registrado el país que intenta agregar!"
          );
          registro = false;
          return;
        }

        //Validar que no seleccione "Ambos"
        if (this.datosRolActor.rolesActorId === 3) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            "No se permite registrar 'Ambos' para la modalidad triangular"
          );
          registro = false;
          return;
        }

        //Validar cuando el que se desea registrar es oferente
        if (this.datosRolActor.rolesActorId === 1 && oferentes > 1) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            "Solo se pueden registrar dos oferentes para la modalidad triangular"
          );
          registro = false;
          return;
        }

        //Validar cuando el que se desea registrar es receptor
        if (this.datosRolActor.rolesActorId === 2 && receptores > 0) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            "Solo se puede registrar un oferente para la modalidad bilateral"
          );
          registro = false;
          return;
        }
      }

      // 3 Si es regional solo se puede seleccionar un oferente(1) y n numero de receptores receptor(2);  (ambos(3) ninguna vez)

      if (this.datosItem.modalidadFinanciamientoId === 3) {
        //Validar que no seleccione "Ambos"
        if (this.datosRolActor.rolesActorId === 3) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            "No se permite registrar 'Ambos' para la modalidad triangular"
          );
          registro = false;
          return;
        }

        //Validar cuando el que se desea registrar es oferente
        if (this.datosRolActor.rolesActorId === 1 && oferentes > 0) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            "Solo se pueden registrar dos oferentes para la modalidad triangular"
          );
          registro = false;
          return;
        }
      }

      registro = true;

      //Si pasa las reglas registrar el rolesactor
      if (registro === true) {
        this.btnRegistroRolActorLoading = true;
        this.datosRolActor.iniciativaId = this.iniciativaId;
        await this.$store
          .dispatch(REGISTRAR_ROL_ACTOR_CSS, { datos: this.datosRolActor })
          .then((res) => {
            //console.log(res)
            if (res.status === 200) {
              // this.obtnenerItems();
              // this.dialog=false;
              this.$refs.snackalert.SnackbarShow(
                "success",
                "Mensaje",
                res.message
              );

              //  this.switchItemEstado = true;
              //  this.datosIndicador.detalleIndicador = '';
              this.btnRegistroRolActorLoading = false;
              this.obtenerRolesActores();
            } else {
              this.$refs.snackalert.SnackbarShow(
                "warning",
                "Alerta",
                res.message
              );
            }
            this.btnRegistroRolActorLoading = false;
          })
          .catch((error) => {
            //console.log(error)
            this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
            this.btnRegistroRolActorLoading = false;
          });
      }
    },

    //Eliminar el rolactor
    async eliminarRolActor(id) {
      this.btnRegistroRolActorLoading = true;
      this.datosRolActor.iniciativaId = this.iniciativaId;
      await this.$store
        .dispatch(ELIMINAR_ROL_ACTOR_CSS, id)
        .then((res) => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            //  this.switchItemEstado = true;
            //  this.datosIndicador.detalleIndicador = '';
            this.btnRegistroRolActorLoading = false;
            this.obtenerRolesActores();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroRolActorLoading = false;
        })
        .catch((error) => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnRegistroRolActorLoading = false;
        });
    },

    //Obtener las coberturas registradas
    async obtenerCoberturas() {
      this.coberturas = [];
      this.tableCoberturasLoading = true;

      this.$store
        .dispatch(OBTENER_COBERTURAS_CSS, { id: this.iniciativaId })
        .then((res) => {
          //  console.log(res)
          if (res.status === 200) {
            this.coberturas = res.data;
            //this.datosItem.area === "Rural"  ? this.areaSeleccionada = {id: 1, nombreArea: this.datosItem.area}  : this.areaSeleccionada = {id: 2, nombreArea: this.datosItem.area};
          }
          this.tableCoberturasLoading = false;
        })
        .catch(() => {
          this.tableCoberturasLoading = false;
        });
    },

    ///Registrar datos de cobertura
    async registrarCobertura() {
      this.btnRegistroCoberturaLoading = true;

      this.datosCobertura.area = this.areaSeleccionada.nombreArea;
      this.datosCobertura.iniciativaId = this.iniciativaId;
      await this.$store
        .dispatch(REGISTRAR_COBERTURA_CSS, { datos: this.datosCobertura })
        .then((res) => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            //  this.switchItemEstado = true;
            //  this.datosIndicador.detalleIndicador = '';
            this.btnRegistroCoberturaLoading = false;
            this.obtenerCoberturas();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroCoberturaLoading = false;
        })
        .catch((error) => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnRegistroCoberturaLoading = false;
        });
    },

    //Eliminar registro de cobertura
    async eliminarCobertura(id) {
      this.btnRegistroCoberturaLoading = true;
      this.datosCobertura.iniciativaId = this.iniciativaId;
      await this.$store
        .dispatch(ELIMINAR_COBERTURA_CSS, id)
        .then((res) => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            //  this.switchItemEstado = true;
            //  this.datosIndicador.detalleIndicador = '';
            this.btnRegistroCoberturaLoading = false;
            this.obtenerCoberturas();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroCoberturaLoading = false;
        })
        .catch((error) => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnRegistroCoberturaLoading = false;
        });
    },

    //Registrar la informacion general del proyecto
    async registrarItem() {
      this.btnRegistroLoading = true;

      if (this.accion === 1) {
        this.cargarArchivo().then(() => {
          //?Validar que el archivo fue cargado
          if (this.archivoCargado) {
            this.registrarDatosGeneralesIniciativa();
          } else {
            this.btnDatosGeneralesLoading = false;
          }
        });
      } else if (this.accion === 2) {
        if (this.archivoReemplazado === true) {
          this.cargarArchivo().then(() => {
            //?Validar que el archivo fue cargado
            if (this.archivoCargado) {
              this.registrarDatosGeneralesIniciativa();
            } else {
              this.btnRegistroLoading = false;
            }
          });
        } else {
          this.registrarDatosGeneralesIniciativa();
        }
      }

      /*
        if(!this.archivoExiste){
          //Cargar Archivo 
          this.cargarArchivo()
          .then(() => {
          
              //?Validar que el archivo fue cargado
              if(this.archivoCargado){
                  this.registrarDatosGeneralesIniciativa();
              } else {
                  this.btnRegistroLoading=false;
              }
          })
          .catch(() => {
              this.btnRegistroLoading=false;
          }); 
      } else {
          this.registrarDatosGeneralesIniciativa();
      }*/
    },

    async cargarArchivo() {
      const file = this.documentoMecanismo;

      await this.$store
        .dispatch(CARGAR_ARCHIVO, {
          file: file,
          path: "\\css\\negociaciones\\iniciativas\\respaldos\\",
        })
        .then((res) => {
          //console.log(res)
          if (res.status === 200) {
            this.archivoCargado = true;
            this.datosItem.pathDocumentoIniciativa = res.pathArchivo;
            return true;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
            this.archivoCargado = false;
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    },

    //Registrar los datos principales de la iniciativa
    async registrarDatosGeneralesIniciativa() {
      this.datosItem.tipoNegociacionId = 1;
      this.datosItem.negociacionId = this.negociacionId;

      //!Asignar el estado a datos empresa segun lo seleccionado en el swithc del formulario
      this.switchItemEstado
        ? (this.datosItem.estadosId = 1)
        : (this.datosItem.estadosId = 2);

      this.datosItem.usuarioCreacion = "admin";

      /* this.datosItem.fechaAprobacion = moment(this.fechaAprobacion, "DD-MM-YYYY").format("YYYY-MM-DD");
      this.datosItem.iniciaVigencia = moment(this.iniciaVigencia, "DD-MM-YYYY").format("YYYY-MM-DD");
      this.datosItem.finalizaVigencia = moment(this.finalizaVigencia, "DD-MM-YYYY").format("YYYY-MM-DD");*/
      //?Si action es 1 se realiza el registro
      if (this.accion === 1) {
        await this.$store
          .dispatch(REGISTRAR_INICIATIVA, this.datosItem)
          .then((res) => {
            this.btnLoading = false;
            //this.dialogLoaderVisible = false;
            if (res.status === 200) {
              this.$refs.snackalert.SnackbarShow(
                "success",
                "Mensaje",
                res.message
              );
              //this.obtenerItems();
              //this.dialogRegistro = false;
              //this.resetForm();
              this.accion = 2;
              this.iniciativaId = res.data;
              this.datosItem.id = res.data;
              this.$emit("obtener-items");
              this.$emit("actualizar-accion");
              this.$emit("actualizar-iniciativa", this.iniciativaId);
            } else {
              this.$refs.snackalert.SnackbarShow(
                "warning",
                "Alerta",
                res.message
              );
            }
            this.btnRegistroLoading = false;
          })
          .catch(() => {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error durante el registro.`
            );
          });
      }
      //?Si action es 2 se realiza la actualizacion
      else if (this.accion === 2) {
        this.$store
          .dispatch(ACTUALIZAR_INICIATIVA, {
            datosItem: this.datosItem,
            id: this.datosItem.id,
          })
          .then((res) => {
            //this.btnLoading = false;
            //this.dialogLoaderVisible = false;
            this.btnRegistroLoading = false;

            if (res.status === 200) {
              this.$refs.snackalert.SnackbarShow(
                "success",
                "Mensaje",
                res.message
              );
              this.$emit("obtener-items");
              this.iniciativaId = this.datosItem.id;
              this.accion = 2;
              //this.obtenerItems();
              //this.dialogRegistro = false;
              //this.resetForm();
            } else {
              this.$refs.snackalert.SnackbarShow(
                "warning",
                "Alerta",
                res.message
              );
            }
          })
          .catch(() => {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error durante el registro.`
            );
          });
      }
    },

    //Descargar el archivo
    descargarArchivo(path) {
      DownloadFile.download(path);
    },
  },

  created() {
    this.obtenerTiposIniciativa();
    this.obtenerModalidadesFinanciamiento();
    this.obtenerEstadosNegociacion();
    //this.obtenerRoles();
    this.obtenerInstituciones();
    this.obtenerInstitucionesExtranjeras();
    this.obtenerSectores();
    this.obtenerAreas();
    this.obtenerSectoresSisCss();
    this.obtenerPaises();
    this.obtenerDepartamentos();
    this.obtenerInstitucionesNacionales();
    //this.obtenerRolesActores();
    this.obtenerCoberturas();
    this.datosItem.nombreIniciativa = this.nombreNegociacion;

    //this.obtenerMunicipiosPorDepartamento();
    if (this.accion === 2) {
      this.obtenerIniciativa(this.iniciativaId);
    }

    /*  this.parameters = this.$route.params;
    this.negociacionId = this.$CryptoJS.AES.decrypt(
      this.parameters.negociacionId,
      "KEYADMINCNS2022"
    ).toString(this.CryptoJS.enc.Utf8);
    */
  },

  computed: {
    headersActores() {
      return [
        {
          text: "Rol",
          align: "start",
          sortable: false,
          value: "Rol",
        },
        {
          text: "País",
          align: "start",
          sortable: true,
          value: "pais",
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "",
        },
      ];
    },

    headersCobertura() {
      return [
        {
          text: "Departamento",
          align: "start",
          sortable: false,
          value: "Departamento",
        },
        {
          text: "Municipio",
          align: "start",
          sortable: true,
          value: "Municipio",
        },
        {
          text: "Área",
          align: "start",
          sortable: true,
          value: "area",
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "",
        },
      ];
    },

    headersInstituciones() {
      return [
        {
          text: "Institución",
          align: "start",
          sortable: false,
          value: "institucion",
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "",
        },
      ];
    },
  },
};
</script>
