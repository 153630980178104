<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-3">
        <v-subheader class="text-h5 black--text"> Presupuesto </v-subheader>
      </v-col>
    </v-row>

    <!-- costos por rol y actores global-->
    <v-card class="mt-6" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
            <v-subheader class="text-h6 black--text">
              Costos generales presupuestados
            </v-subheader>
          </v-col>
        </v-row>
        <v-form
          ref="form"
          v-on:submit.prevent="registrarCostosGenerales"
          v-model="validFormCostosGenerales"
        >
          <v-row class="mt-4">
            <!--inicio:: campo -->
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-text-field
                autocomplete="off"
                class="required"
                dense
                filled
                color="blue-grey lighten-2"
                v-model="montoTotalDPresupuestoGeneral"
                label="Monto total iniciativa US$ Dólares"
                :rules="[
                  required('Monto total iniciativa US$ dólares'),
                  minLength('Monto total iniciativa US$ dólares', 1),
                  maxLength('Monto total iniciativa US$ dólares', 20),
                  decimals('Monto total iniciativa US$ dólares'),
                ]"
                maxlength="20"
              ></v-text-field>
            </v-col>
            <!--fin:: campo-->
            <!--inicio:: campo -->
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-text-field
                autocomplete="off"
                class="required"
                dense
                filled
                color="blue-grey lighten-2"
                v-model="montoTotalQPresupuestoGeneral"
                label="Monto total iniciativa Q"
                :rules="[
                  required('Monto total iniciativa Q'),
                  minLength('Monto total iniciativa Q', 1),
                  maxLength('Monto total iniciativa Q', 20),
                  decimals('Monto total iniciativa Q'),
                ]"
                maxlength="20"
              ></v-text-field>
            </v-col>
            <!--fin:: campo-->
          </v-row>

          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12">
              <v-spacer></v-spacer>
              <v-btn
                color="light-blue-502"
                class="white--text mb-2 float-right"
                type="submit"
                :elevation="0"
                :disabled="!validFormCostosGenerales"
                :loading="btnRegistroCostosGenerales"
              >
                Guardar información
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- costos por rol y actores global-->
    <v-card
      class="mt-6"
      outlined
      v-if="datosPresupuestoGeneral && datosPresupuestoGeneral.id"
    >
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
            <v-subheader class="text-h6 black--text">
              Costos presupuestados según rol de actores
            </v-subheader>
          </v-col>
        </v-row>
        <v-form
          ref="form"
          v-on:submit.prevent="registrarCostosActores"
          v-model="validFormCostosActores"
        >
          <v-row class="mt-6">
            <!--inicio:: campo
                    <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                        <v-select
                            dense
                            filled
                            v-model="datosItem.rolesActorId"
                            :items="roles"
                            class="required"
                            color="blue-grey lighten-2"
                            label="Rol"
                            item-text="nombre"
                            item-value="id"
                            menu-props="auto"
                            :rules="[
                                selectRequired('rol'),
                            ]"
                            @change="obtenerPaisRol(datosItem.rolesActorId)"
                        >
                        </v-select>
                    </v-col>
                    fin:: campo-->

            <!--inicio:: campo-->
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-autocomplete
                dense
                filled
                v-model="paisSeleccionado"
                :items="paises"
                :loading="ddPaisesLoading"
                class="required"
                color="blue-grey lighten-2"
                label="Actor (País)"
                item-text="nombre"
                item-value="id"
                return-object
                menu-props="auto"
                :rules="[selectRequired('actor')]"
              >
              </v-autocomplete>
            </v-col>
            <!--fin:: campo-->

            <!--inicio:: campo-->
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-select
                dense
                filled
                v-model="datosItem.tipoAporteId"
                :items="tiposAporte"
                class="required"
                color="blue-grey lighten-2"
                label="Tipo de aporte"
                item-text="aporte"
                item-value="id"
                menu-props="auto"
                :rules="[selectRequired('aporte')]"
              >
              </v-select>
            </v-col>
            <!--fin:: campo-->
          </v-row>

          <v-row>
            <!--inicio:: campo-->
            <v-col
              cols="12"
              md="12"
              sm="12"
              class="pt-0 pb-0"
              v-if="datosItem.tipoAporteId === 1"
            >
              <v-select
                dense
                filled
                v-model="fuenteFinanciamiento"
                :items="fuentesFinanciamiento"
                :disabled="datosItem.tipoAporteId != 1"
                class="required"
                color="blue-grey lighten-2"
                label="Fuente de financiamiento"
                item-text="fuente"
                item-value="id"
                return-object
                menu-props="auto"
                :rules="[
                  datosItem.tipoAporteId === 1
                    ? selectRequired('fuente financiamiento')
                    : true,
                ]"
              >
              </v-select>
            </v-col>
            <!--fin:: campo-->
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <v-alert dense border="left" type="warning">
                Para verificar el tipo de cambio oficial en el
                <strong>banco de Guatemala</strong> haga click
                <a
                  href="https://www.banguat.gob.gt/tipo_cambio/"
                  target="_blank"
                  class="white--text"
                  >aquí</a
                >
              </v-alert>
            </v-col>

            <!--inicio:: campo -->
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-text-field
                autocomplete="off"
                :class="
                  datosItem.tipoAporteId != 4 && datosItem.tipoAporteId != 2
                    ? `required`
                    : ``
                "
                dense
                filled
                color="blue-grey lighten-2"
                v-model="fechaCambioCostosGenerales"
                label="Fecha de registro de tipo de cambio"
                :rules="[
                  datosItem.tipoAporteId != 4 && datosItem.tipoAporteId != 2
                    ? required('fecha de registro de tipo de cambio')
                    : true,
                  datosItem.tipoAporteId != 4 && datosItem.tipoAporteId != 2
                    ? minLength('fecha de registro de tipo de cambio', 1)
                    : true,
                  fechaCambioCostosGenerales.lenght > 0
                    ? maxLength('fecha de registro de tipo de cambio', 10)
                    : true,
                  fechaCambioCostosGenerales.lenght > 0
                    ? dateFormat('fecha de registro de tipo de cambio')
                    : true,
                ]"
                maxlength="10"
                v-mask="'##/##/####'"
              ></v-text-field>
            </v-col>
            <!--fin:: campo-->

            <!--inicio:: campo -->
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-text-field
                autocomplete="off"
                :class="
                  datosItem.tipoAporteId != 4 && datosItem.tipoAporteId != 2
                    ? `required`
                    : ``
                "
                dense
                filled
                color="blue-grey lighten-2"
                v-model="tipoCambioCostosGenerales"
                label="Tipo de cambio"
                :rules="[
                  datosItem.tipoAporteId != 4 && datosItem.tipoAporteId != 2
                    ? required('tipo de cambio')
                    : true,
                  datosItem.tipoAporteId != 4 && datosItem.tipoAporteId != 2
                    ? minLength('tipo de cambio', 1)
                    : true,
                  tipoCambioCostosGenerales.length > 0
                    ? maxLength('tipo de cambio', 20)
                    : true,
                  tipoCambioCostosGenerales.length > 0
                    ? decimals10('tipo de cambio')
                    : true,
                ]"
                maxlength="20"
              ></v-text-field>
            </v-col>
            <!--fin:: campo-->
          </v-row>

          <v-row>
            <!--inicio:: campo -->
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-text-field
                autocomplete="off"
                :class="
                  (datosItem.tipoAporteId != 4 &&
                    datosItem.tipoAporteId != 2) ||
                  this.tipoCambioCostosGenerales.length > 0
                    ? `required`
                    : ``
                "
                dense
                filled
                color="blue-grey lighten-2"
                v-model="costosPresupuestadosD"
                label="Costo Presupuestado Dólares"
                :rules="[
                  (datosItem.tipoAporteId != 4 &&
                    datosItem.tipoAporteId != 2) ||
                  this.tipoCambioCostosGenerales.length > 0
                    ? required('costo presupuestado dólares')
                    : true,
                  (datosItem.tipoAporteId != 4 &&
                    datosItem.tipoAporteId != 2) ||
                  this.tipoCambioCostosGenerales.length > 0
                    ? minLength('costo presupuestado dólares', 1)
                    : true,
                  this.tipoCambioCostosGenerales.length > 0 &&
                  this.costosPresupuestadosD.length > 0
                    ? maxLength('costo presupuestado dólares', 20)
                    : true,
                  this.tipoCambioCostosGenerales.length > 0 &&
                  this.costosPresupuestadosD.length > 0
                    ? decimals('costo presupuestado dólares')
                    : true,
                ]"
                maxlength="20"
              ></v-text-field>
            </v-col>
            <!--fin:: campo-->
            <!--inicio:: campo -->
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-text-field
                autocomplete="off"
                :disabled="true"
                :class="
                  datosItem.tipoAporteId != 4 && datosItem.tipoAporteId != 2
                    ? `required`
                    : ``
                "
                dense
                filled
                color="blue-grey lighten-2"
                v-model="costosPresupuestadosQ"
                label="Costo Presupuestado Quetzales"
                :rules="[
                  datosItem.tipoAporteId != 4 &&
                  datosItem.tipoAporteId != 2 &&
                  this.tipoCambioCostosGenerales.length > 0 &&
                  this.costosPresupuestadosD.length > 0
                    ? required('costo presupuestado quetzales')
                    : true,
                  datosItem.tipoAporteId != 4 &&
                  datosItem.tipoAporteId != 2 &&
                  this.tipoCambioCostosGenerales.length > 0 &&
                  this.costosPresupuestadosD.length > 0
                    ? minLength('costo presupuestado quetzales', 1)
                    : true,
                  this.tipoCambioCostosGenerales.length > 0 &&
                  this.costosPresupuestadosD.length > 0
                    ? maxLength('costo presupuestado quetzales', 20)
                    : true,
                  this.tipoCambioCostosGenerales.length > 0 &&
                  this.costosPresupuestadosD.length > 0
                    ? decimals('costo presupuestado quetzales')
                    : true,
                ]"
                maxlength="20"
              ></v-text-field>
            </v-col>
            <!--fin:: campo-->
          </v-row>

          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12">
              <v-spacer></v-spacer>
              <v-btn
                color="light-blue-502"
                class="white--text mb-2 float-right"
                type="submit"
                :elevation="0"
                :disabled="!validFormCostosActores"
                :loading="btnRegistroCostosGlobales"
              >
                Guardar información
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <v-row>
          <v-col cols="12" md="12" sm="12">
            <template>
              <v-data-table
                :headers="headersCostosPresupuestados"
                :items="costosGlobales"
                :items-per-page="5"
                :loading="tableCostosGeneralesLoading"
                :header-props="{
                  sortByText: 'Ordenar por',
                }"
                no-data-text="No se encontraron registros"
                loading-text="Cargando..."
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}',
                }"
                class="elevation-1"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.id }}</td>
                    <td>{{ item.rolActor }}</td>
                    <td>{{ item.nombre }}</td>
                    <td>{{ item.aporte }}</td>
                    <td>{{ item.fuenteFinanciera }}</td>
                    <td>
                      GTQ {{ item.costosPresupuestadosQ }} US$
                      {{ item.costosPresupuestadosD }}
                    </td>
                    <td>
                      <v-chip
                        class="ma-2 font-weight-medium"
                        label
                        :color="
                          item.estadosId === 1
                            ? 'blue lighten-4'
                            : item.estadosId === 2
                            ? 'deep-orange lighten-4'
                            : item.estadosId === 6
                            ? 'cyan lighten-4'
                            : item.estadosId === 7
                            ? 'deep-orange lighten-4'
                            : 'pink lighten-4'
                        "
                        :text-color="
                          item.estadosId === 1
                            ? 'blue lighten-1'
                            : item.estadosId === 2
                            ? 'deep-orange lighten-1'
                            : item.estadosId === 6
                            ? 'cyan darken-1'
                            : item.estadosId === 7
                            ? 'deep-orange lighten-1'
                            : 'pink darken-1'
                        "
                        small
                      >
                        {{ item.estado }}
                      </v-chip>
                    </td>
                    <td>
                      <v-btn
                        v-if="item.estadosId === 1 || item.estadosId === 4"
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        depressed
                        color="blue-grey lighten-5"
                        @click="eliminarCostoGeneral(item.id)"
                        :disabled="btnEliminarCostoGenerallDisabled"
                      >
                        <v-icon left>mdi-delete</v-icon> Eliminar
                      </v-btn>

                      <v-btn
                        v-if="item.estadosId === 1"
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        depressed
                        color="blue-grey lighten-5"
                        @click="
                          mostrarFormularioPresupuestoAnual(
                            item.id,
                            item.rolesActorId,
                            item.paisId,
                            item.costosPresupuestadosD
                          )
                        "
                      >
                        <v-icon left>mdi-calendar-range</v-icon> Presupuesto
                        anual
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!--inicio:: costo por rol, actores y año-->
    <v-card class="mt-6" outlined v-if="formularioCostosAnuales">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 mt-4 pb-0 mb-0">
            <v-subheader class="text-h6 black--text">
              Costos presupuestados por año según rol de actores |
              {{ totalPresupuesto }}
            </v-subheader>
          </v-col>
        </v-row>
        <v-form
          ref="form"
          v-on:submit.prevent="registrarCostosActoresAnual"
          v-model="validFormCostosActoresAnuales"
        >
          <v-row class="mt-6">
            <!--inicio:: campo-->
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-select
                dense
                filled
                v-model="datosPresupuestoAnual.periodosId"
                :items="periodos"
                class="required"
                color="blue-grey lighten-2"
                label="Periodo"
                item-text="periodo"
                item-value="id"
                menu-props="auto"
                :rules="[selectRequired('periodo')]"
              >
              </v-select>
            </v-col>
            <!--fin:: campo-->
          </v-row>

          <v-row>
            <!--inicio:: campo-->
            <!-- <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                        <v-select
                            dense
                            :disabled="true"
                            filled
                            v-model="datosPresupuestoAnual.rolesActorId"
                            :items="roles"
                            class="required"
                            color="blue-grey lighten-2"
                            label="Rol"
                            item-text="nombre"
                            item-value="id"
                            menu-props="auto"
                            :rules="[
                                selectRequired('rol'),
                            ]"
                        >
                        </v-select>
                    </v-col> -->
            <!--fin:: campo-->

            <!--inicio:: campo-->
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-autocomplete
                dense
                :disabled="true"
                filled
                v-model="paisSeleccionadoAnual"
                :items="paises"
                class="required"
                color="blue-grey lighten-2"
                label="Actor (País / Organización)"
                item-text="nombre"
                item-value="id"
                menu-props="auto"
                return-object
                :rules="[selectRequired('actor')]"
              >
              </v-autocomplete>
            </v-col>
            <!--fin:: campo-->
          </v-row>

          <v-row>
            <!--inicio:: campo -->
            <v-col cols="12" md="6" sm="12" class="pt-4 pb-0">
              <v-text-field
                autocomplete="off"
                class="required"
                dense
                filled
                color="blue-grey lighten-2"
                v-model="tipoCambioCostosAnuales"
                label="Tipo de cambio"
                :rules="[
                  required('tipo de cambio'),
                  minLength('tipo de cambio', 1),
                  maxLength('tipo de cambio', 20),
                  decimals10('tipo de cambio'),
                ]"
                maxlength="20"
              ></v-text-field>
            </v-col>
            <!--fin:: campo-->

            <!--inicio:: campo -->
            <v-col cols="12" md="6" sm="12" class="pt-4 pb-0">
              <v-text-field
                autocomplete="off"
                class="required"
                dense
                filled
                color="blue-grey lighten-2"
                v-model="fechaCambioCostosAnuales"
                label="Fecha de registro de tipo de cambio"
                :rules="[
                  required('fecha de registro de tipo de cambio'),
                  minLength('fecha de registro de tipo de cambio', 1),
                  maxLength('fecha de registro de tipo de cambio', 10),
                  dateFormat('fecha de registro de tipo de cambio'),
                ]"
                maxlength="10"
                v-mask="'##/##/####'"
              ></v-text-field>
            </v-col>
            <!--fin:: campo-->
          </v-row>

          <v-row>
            <!--inicio:: campo -->
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-text-field
                autocomplete="off"
                class="required"
                dense
                filled
                color="blue-grey lighten-2"
                v-model="presupuestoAnualD"
                label="Costo Presupuestado Dólares"
                :rules="[
                  required('costo presupuestado dólares'),
                  minLength('costo presupuestado dólares', 1),
                  maxLength('costo presupuestado dólares', 20),
                  decimals('costo presupuestado dólares'),
                ]"
                maxlength="20"
              ></v-text-field>
            </v-col>
            <!--fin:: campo-->

            <!--inicio:: campo -->
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-text-field
                autocomplete="off"
                class="required"
                dense
                :disabled="true"
                filled
                color="blue-grey lighten-2"
                v-model="presupuestadosAnualQ"
                label="Costo Presupuestado Quetzales"
                :rules="[
                  required('costo presupuestado quetzales'),
                  minLength('costo presupuestado quetzales', 1),
                  maxLength('costo presupuestado quetzales', 20),
                  decimals('costo presupuestado quetzales'),
                ]"
                maxlength="20"
              ></v-text-field>
            </v-col>
            <!--fin:: campo-->
          </v-row>

          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12">
              <v-spacer></v-spacer>
              <v-btn
                color="light-blue-502"
                class="white--text mb-2 float-right"
                type="submit"
                :elevation="0"
                :disabled="!validFormCostosActoresAnuales"
                :loading="btnRegistroCostosAnuales"
              >
                Guardar información
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <v-row>
          <v-col cols="12" md="12" sm="12">
            <template>
              <v-data-table
                :headers="headersCostosAnuales"
                :items="costosAnuales"
                :loading="tableCostosAnualesLoading"
                :items-per-page="5"
                :header-props="{
                  sortByText: 'Ordenar por',
                }"
                no-data-text="No se encontraron registros"
                loading-text="Cargando..."
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}',
                }"
                class="elevation-1"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.id }}</td>
                    <td>{{ item.periodo }}</td>
                    <td>{{ item.rolActor }}</td>
                    <td>{{ item.nombre }}</td>
                    <td>{{ item.tipoCambio }} | {{ item.fechaCambio }}</td>
                    <td>
                      GTQ {{ item.presupuestadosQ }} | USDM
                      {{ item.presupuestadosD }}
                    </td>
                    <td>
                      <v-chip
                        class="ma-2 font-weight-medium"
                        label
                        :color="
                          item.estadosId === 1
                            ? 'blue lighten-4'
                            : item.estadosId === 2
                            ? 'deep-orange lighten-4'
                            : item.estadosId === 6
                            ? 'cyan lighten-4'
                            : item.estadosId === 7
                            ? 'deep-orange lighten-4'
                            : 'pink lighten-4'
                        "
                        :text-color="
                          item.estadosId === 1
                            ? 'blue lighten-1'
                            : item.estadosId === 2
                            ? 'deep-orange lighten-1'
                            : item.estadosId === 6
                            ? 'cyan darken-1'
                            : item.estadosId === 7
                            ? 'deep-orange lighten-1'
                            : 'pink darken-1'
                        "
                        small
                      >
                        {{ item.estado }}
                      </v-chip>
                    </td>
                    <td>
                      <v-btn
                        v-if="item.estadosId === 1 || item.estadosId === 4"
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        depressed
                        color="blue-grey lighten-5"
                        :disabled="btnEliminarCostoAnualDisabled"
                        @click="eliminarCostoAnual(item.id)"
                      >
                        <v-icon left>mdi-delete</v-icon> Eliminar
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!--fin:: costo por rol, actores y año-->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
  </div>
</template>


<script>
import moment from "moment";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
//import { OBTENER_PAISES } from "@/core/services/store/catalogos/paises/pais.module";

import {
  OBTENER_COSTOS_GLOBALES,
  OBTENER_PRESUPUESTO_GENERAL_INICIATIVA_CSS,
  REGISTRAR_PRESUPUESTO_GENERAL,
  ACTUALIZAR_PRESUPUESTO_GENERAL,
  REGISTRAR_PRESUPUESTO_ACTORES,
  OBTENER_COSTOS_ANUALES,
  REGISTRAR_PRESUPUESTO_AUAL,
  ELIMINAR_PRESUPUESTO_GENERAL,
  ELIMINAR_PRESUPUESTO_ANUAL,
} from "@/core/services/store/negociaciones/iniciativas/presupuestos/presupuesto.module";
export default {
  name: "SeccionPresupuesto",
  components: {
    SnackAlert,
  },
  props: ["iniciativaId", "negociacionId"],

  data() {
    return {
      validForm: false,
      validFormCostosActores: false,
      validFormCostosActoresAnuales: false,
      tableCostosAnualesLoading: false,
      tableCostosGeneralesLoading: false,
      formularioCostosAnuales: false,
      validFormCostosTiposAporte: false,
      btnRegistroLoading: false,
      btnRegistroCostosGlobales: false,
      btnRegistroCostosAnuales: false,
      btnRegistroTiposAporte: false,
      btnEliminarCostoGenerallDisabled: false,
      btnEliminarCostoAnuallDisabled: false,
      validFormCostosActoresAnual: false,
      costosPresupuestadosD: "",
      costosPresupuestadosQ: "",
      costosPresupuestadosDAnual: "",
      costosPresupuestadosQAnual: "",
      tipoCambioCostosGenerales: "",
      fechaCambioCostosGenerales: "",
      tipoCambioCostosAnuales: "",
      paisSeleccionadoAnual: {},
      fechaCambioCostosAnuales: "",
      presupuestoAnualD: "",
      presupuestadosAnualQ: "",
      costosGlobales: [],
      costosAnuales: [],
      periodos: [],
      fuenteFinanciamiento: {},
      paisSeleccionado: {},
      datosItem: {
        tipoCambio: "",
        fechaCambio: "",
        fuenteFinanciera: "",
        tipoAporteId: 0,
        fuenteFinanciamientoId: 0,
        costosPresupuestadosQ: "",
        costosPresupuestadosD: "",
        estadosId: 1,
        paisId: 0,
        rolesActorId: 0,
        usuarioCreacion: "admin",
      },
      datosPresupuestoAnual: {
        fechaCambio: "",
        tipoCambio: "",
        periodosId: 0,
        presupuestadosQ: "",
        presupuestadosD: "",
        presupuestoId: 0,
        paisId: 0,
        rolesActorId: 0,
        estadosId: 1,
        usuarioCreacion: "admin",
      },
      costoGeneralSeleccionado: 0,
      costosActoresGlobales: [],
      costosActoresAnuales: [],
      costosTiposAporte: [],
      roles: [
        { id: 1, rol: "Receptor" },
        { id: 2, rol: "Oferente" },
      ],
      rolSeleccionado: {},
      rolSeleccionadoAnual: {},
      actorSeleccionado: {},
      actorSeleccionadoAnual: {},
      paises: [],
      tiposAporte: [],
      fuentesFinanciamiento: [
        { id: 1, fuente: "Recursos Nacionales" },
        { id: 2, fuente: "Recursos Extranjeros" },
      ],
      anios: [
        { id: 1, anio: "2020" },
        { id: 2, anio: "2021" },
        { id: 3, anio: "2022" },
        { id: 4, anio: "2023" },
        { id: 5, anio: "2024" },
      ],
      rolSeleccionadoTipoAporte: {},
      actorSeleccionadoTipoAporte: {},
      costosPresupuestadosQTipoAporte: "",
      costosPresupuestadosDTipoAporte: "",
      tipoAporteSeleccionado: {},
      nombreFuente: "",
      paisRol: {},
      ddPaisesLoading: false,
      totalPresupuesto: 0,
      totalCostosAnualesQ: 0,
      totalCostosAnualesD: 0,
      montoTotalQPresupuestoGeneral: "",
      montoTotalDPresupuestoGeneral: "",
      // presupuestoGeneral:{}
      accionPresupuestoGeneral: 1,
      datosPresupuestoGeneral: {
        montoTotalIniciativa_Q: "",
        montoTotalIniciativa_D: "",
        estadosId: 1,
        iniciativaId: this.iniciativaId,
        usuarioCreacion: "admin",
      },
      validFormCostosGenerales: false,
      btnRegistroCostosGenerales: false,
      rolesActorIdAnual: 0,
      presupuestoId: 0,
      paisIdAnual: 0,
      ...validations,
    };
  },

  methods: {
    resetItems() {
      this.costosGlobales = [];
      this.costosAnuales = [];
      this.fuenteFinanciamiento = {};
    },
    //Obtener los roles de los actores
    async obtenerRoles() {
      this.roles = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "RolesActores/all/1" })
        .then((res) => {
          if (res.status === 200) {
            this.roles = res.data;
          }
        })
        .catch(() => {
          this.roles = [];
        });
    },

    //Obtener los roles de los actores
    async obtenerTiposAporte() {
      this.tiposAporte = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "TipoAporte/all/1" })
        .then((res) => {
          if (res.status === 200) {
            this.tiposAporte = res.data;
          }
        })
        .catch(() => {
          this.tiposAporte = [];
        });
    },

    //Obtener los paises
    async obtenerPaises() {
      this.paises = [];
      this.ddPaisesLoading = true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `Pais/Actores/${this.negociacionId}`,
        })
        .then((res) => {
          if (res.status === 200) {
            this.paises = res.data;
          }
          //console.log(this.paises);
          //console.log(this.items)
          this.ddPaisesLoading = false;
        })
        .catch(() => {
          this.ddPaisesLoading = false;
        });
    },

    //Obtener los periodos
    async obtenerPeriodos() {
      this.periodos = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Periodos/all/1" })
        .then((res) => {
          if (res.status === 200) {
            this.periodos = res.data;
          }
        })
        .catch(() => {
          this.periodos = [];
        });
    },

    //Obtener pais en base al rol seleccionado
    async obtenerPaisRol(rolActorId) {
      this.paises = [];
      this.ddPaisesLoading = true;
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `Pais/Actores/${rolActorId}/${this.negociacionId}`,
        })
        .then((res) => {
          //  console.log(res)
          if (res.status === 200) {
            this.paises = res.data;
            //this.datosItem.paisId = this.paisRol.id;
          }
          this.ddPaisesLoading = false;
        })
        .catch(() => {
          this.ddPaisesLoading = false;
          this.paises = [];
        });
    },

    async obtenerPresupuestoGeneral(iniciativaId) {
      this.datosPresupuestoGeneral = {};
      this.tableCostosGeneralesLoading = true;

      this.$store
        .dispatch(OBTENER_PRESUPUESTO_GENERAL_INICIATIVA_CSS, {
          iniciativaId: iniciativaId,
        })
        .then((res) => {
          if (res.status === 200) {
            this.datosPresupuestoGeneral = res.data;
            this.accionPresupuestoGeneral = 2;
            this.montoTotalQPresupuestoGeneral =
              this.datosPresupuestoGeneral.montoTotalIniciativa_Q.toString();
            this.montoTotalDPresupuestoGeneral =
              this.datosPresupuestoGeneral.montoTotalIniciativa_D.toString();

            this.obtenerCostosGlobales(this.datosPresupuestoGeneral.id);
          } else {
            this.accionPresupuestoGeneral = 1;
            this.datosPresupuestoGeneral = {
              montoTotalIniciativa_Q: "",
              montoTotalIniciativa_D: "",
              estadosId: 1,
              iniciativaId: this.iniciativaId,
              usuarioCreacion: "admin",
            };
          }
          this.skeletonLoading = false;
          this.tableCostosGeneralesLoading = false;
        })
        .catch(() => {
          this.skeletonLoading = false;
          this.datosPresupuestoGeneral = {
            montoTotalIniciativa_Q: "",
            montoTotalIniciativa_D: "",
            estadosId: 1,
            iniciativaId: this.iniciativaId,
            usuarioCreacion: "admin",
          };
        });
    },

    async registrarItem() {
      this.$refs.snackalert.SnackbarShow(
        "success",
        "Mensaje",
        "Registro exitoso"
      );
    },

    async obtenerCostosGlobales(iniciativaId) {
      this.costosGlobales = [];
      this.tableCostosGeneralesLoading = true;

      this.$store
        .dispatch(OBTENER_COSTOS_GLOBALES, {
          estadoId: 1,
          iniciativaId: iniciativaId,
        })
        .then((res) => {
          if (res.status === 200) {
            this.costosGlobales = res.data;
          }
          this.skeletonLoading = false;
          this.tableCostosGeneralesLoading = false;
        })
        .catch(() => {
          this.skeletonLoading = false;
          this.tableCostosGeneralesLoading = false;
        });
    },

    async obtenerCostosAnuales(presupuestoId) {
      this.costosAnuales = [];
      this.totalCostosAnualesQ = 0;
      this.totalCostosAnualesD = 0;
      this.tableCostosAnualesLoading = true;
      this.$store
        .dispatch(OBTENER_COSTOS_ANUALES, {
          estadoId: 1,
          presupuestoId: presupuestoId,
        })
        .then((res) => {
          if (res.status === 200) {
            this.costosAnuales = res.data;
            //Sumar el total de Quetzales de los costros anuales
            this.totalCostosAnualesQ = this.costosAnuales.reduce(
              (a, b) => parseFloat(a) + (parseFloat(b["presupuestadosQ"]) || 0),
              0
            );

            this.totalCostosAnualesD = this.costosAnuales.reduce(
              (a, b) => parseFloat(a) + (parseFloat(b["presupuestadosD"]) || 0),
              0
            );
          }
          this.tableCostosAnualesLoading = false;
        })
        .catch(() => {
          this.tableCostosAnualesLoading = false;
        });
    },

    async registrarCostosGenerales() {
      this.btnRegistroCostosGenerales = true;

      this.datosPresupuestoGeneral.montoTotalIniciativa_Q = parseFloat(
        this.montoTotalQPresupuestoGeneral
      );
      this.datosPresupuestoGeneral.montoTotalIniciativa_D = parseFloat(
        this.montoTotalDPresupuestoGeneral
      );

      let dispatch = REGISTRAR_PRESUPUESTO_GENERAL;

      if (this.accionPresupuestoGeneral === 2) {
        dispatch = ACTUALIZAR_PRESUPUESTO_GENERAL;
      }

      //console.log(dispatch + " " + this.accionPresupuestoGeneral)

      await this.$store
        .dispatch(dispatch, { datos: this.datosPresupuestoGeneral })
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerPresupuestoGeneral(this.iniciativaId);
            //this.resetFormPresupuestoGeneral();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroCostosGenerales = false;
        })
        .catch((error) => {
          this.btnRegistroCostosGenerales = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Realizar el registro de la subsección Costos presupuestados para toda la iniciativa segun rol de los actores
    async registrarCostosActores() {
      this.btnRegistroCostosGlobales = true;

      this.datosItem.presupuestoGeneralId = this.datosPresupuestoGeneral.id;

      this.tipoCambioCostosGenerales.length > 0
        ? (this.datosItem.tipoCambio = this.tipoCambioCostosGenerales)
        : (this.datosItem.tipoCambio = null);
      this.fechaCambioCostosGenerales.length > 0
        ? (this.datosItem.fechaCambio = moment(
            this.fechaCambioCostosGenerales,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD"))
        : (this.datosItem.fechaCambio = null);

      this.costosPresupuestadosD.length <= 0
        ? (this.datosItem.costosPresupuestadosD = null)
        : (this.datosItem.costosPresupuestadosD = parseFloat(
            this.costosPresupuestadosD
          ));

      this.costosPresupuestadosQ.length <= 0
        ? (this.datosItem.costosPresupuestadosQ = null)
        : (this.datosItem.costosPresupuestadosQ = parseFloat(
            this.costosPresupuestadosQ
          ));

      //this.datosItem.paisId = this.paisSeleccionado.id;
      this.datosItem.rolesActorId = this.paisSeleccionado.rolesActorId;

      this.datosItem.tipoAporteId === 1
        ? (this.datosItem.fuenteFinanciera = this.fuenteFinanciamiento.fuente)
        : (this.datosItem.fuenteFinanciera = "");

      delete this.datosItem.organismosActoresId;
      delete this.datosItem.paisId;

      if (this.paisSeleccionado.grupo === "Organismo")
        this.datosItem.organismosActoresId = this.paisSeleccionado.id;
      else this.datosItem.paisId = this.paisSeleccionado.id;

      await this.$store
        .dispatch(REGISTRAR_PRESUPUESTO_ACTORES, { datos: this.datosItem })
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerCostosGlobales(this.datosPresupuestoGeneral.id);
            this.resetFormCostosActores();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroCostosGlobales = false;
        })
        .catch((error) => {
          this.btnRegistroCostosGlobales = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    resetFormCostosActores() {
      this.costosPresupuestadosQ = "";
      this.costosPresupuestadosD = "";
      this.tipoCambioCostosGenerales = "";
      this.fechaCambioCostosGenerales = "";
      this.fuenteFinanciamiento = {};
      this.datosItem = {
        tipoCambio: 0,
        fechaCambio: "",
        fuenteFinanciera: "",
        tipoAporteId: 0,
        fuenteFinanciamientoId: 0,
        costosPresupuestadosQ: "",
        costosPresupuestadosD: "",
        estadosId: 1,
        paisId: 0,
        rolesActorId: 0,
        usuarioCreacion: "admin",
      };
    },

    resetFormPresupuestoGeneral() {
      this.datosPresupuestoGeneral = {
        montoTotalIniciativa_Q: "",
        montoTotalIniciativa_D: "",
        estadosId: 1,
        iniciativaId: this.iniciativaId,
        usuarioCreacion: "admin",
      };
    },

    async mostrarFormularioPresupuestoAnual(
      id,
      rolesActorId,
      paisId,
      costoPresupuestadoQ
    ) {
      //this.obtenerCostosAnuales(this.iniciativaId);
      this.resetFormCostosAnuales();
      this.totalPresupuesto = costoPresupuestadoQ;
      this.datosPresupuestoAnual.rolesActorId = rolesActorId;
      this.rolesActorIdAnual = rolesActorId;
      this.paisIdAnual = paisId;
      this.datosPresupuestoAnual.paisId = paisId;

      this.paisSeleccionadoAnual = this.paises.find(
        (item) => item.id === paisId
      );

      this.costoGeneralSeleccionado = id;
      this.formularioCostosAnuales = true;
      this.datosPresupuestoAnual.presupuestoId = id;
      this.presupuestoId = id;
      this.obtenerCostosAnuales(id);
    },

    //Realizar el registro de la subsección Costos presupuestados por año
    async registrarCostosActoresAnual() {
      this.btnRegistroCostosAnuales = true;
      this.datosPresupuestoAnual.fuenteFinanciera =
        this.fuenteFinanciamiento.fuente;
      this.datosPresupuestoAnual.iniciativaId = this.iniciativaId;
      this.datosPresupuestoAnual.tipoCambio = this.tipoCambioCostosAnuales;
      this.datosPresupuestoAnual.fechaCambio = this.fechaCambioCostosAnuales;

      this.datosPresupuestoAnual.presupuestadosQ = parseFloat(
        this.presupuestadosAnualQ
      );
      this.datosPresupuestoAnual.presupuestadosD = parseFloat(
        this.presupuestoAnualD
      );

      let totalRegistrar =
        parseFloat(this.datosPresupuestoAnual.presupuestadosD) +
        parseFloat(this.totalCostosAnualesD);

      /* console.log(parseFloat(Number(totalRegistrar).toFixed(2)))
            console.log(typeof parseFloat(Number(totalRegistrar).toFixed(2)))
            
            console.log(parseFloat(Number(this.totalPresupuesto).toFixed(2)))
            console.log(typeof parseFloat(Number(this.totalPresupuesto).toFixed(2))) */

      if (
        parseFloat(Number(totalRegistrar).toFixed(2)) >
        parseFloat(Number(this.totalPresupuesto).toFixed(2))
      ) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `El total de presupuesto anual ${this.totalRegistrar} excede la cantidad de presupuesto global registrada ${this.totalPresupuesto}`
        );
        this.btnRegistroCostosAnuales = false;
        return;
      }

      delete this.datosPresupuestoAnual.organismosActoresId;
      delete this.datosPresupuestoAnual.paisId;

      if (this.paisSeleccionadoAnual.grupo === "Organismo")
        this.datosPresupuestoAnual.organismosActoresId =
          this.paisSeleccionadoAnual.id;
      else this.datosPresupuestoAnual.paisId = this.paisSeleccionadoAnual.id;

      await this.$store
        .dispatch(REGISTRAR_PRESUPUESTO_AUAL, {
          datos: this.datosPresupuestoAnual,
        })
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerCostosAnuales(this.costoGeneralSeleccionado);
            this.resetFormCostosAnuales();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroCostosAnuales = false;
        })
        .catch((error) => {
          this.btnRegistroCostosAnuales = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    resetFormCostosAnuales() {
      let anioActual = new Date().getFullYear();
      let datosPeriodo = this.periodos.find(
        (item) => item.periodo === anioActual.toString()
      );
      this.tipoCambioCostosAnuales = "";
      this.fechaCambioCostosAnuales = "";
      this.costosPresupuestadosQ = "";
      this.costosPresupuestadosD = "";
      this.fuenteFinanciamiento = {};
      //this.presupuestadosAnualQ="";
      this.presupuestoAnualD = "";
      this.datosPresupuestoAnual = {
        tipoCambio: 0,
        fechaCambio: "",
        fuenteFinanciera: "",
        tipoAporteId: 0,
        fuenteFinanciamientoId: 0,
        costosPresupuestadosQ: "",
        costosPresupuestadosD: "",
        estadosId: 1,
        usuarioCreacion: "admin",
        rolesActorId: this.rolesActorIdAnual,
        paisId: this.$storepaisIdAnual,
        presupuestoId: this.presupuestoId,
        periodosId: 0,
      };

      this.datosPresupuestoAnual.periodosId = datosPeriodo.id;
    },

    async eliminarCostoGeneral(id) {
      this.btnEliminarCostoGenerallDisabled = true;
      await this.$store
        .dispatch(ELIMINAR_PRESUPUESTO_GENERAL, id)
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerCostosGlobales(this.datosPresupuestoGeneral.id);
            this.obtenerCostosAnuales(id);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarCostoGenerallDisabled = false;
        })
        .catch((error) => {
          this.btnEliminarCostoGenerallDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    async eliminarCostoAnual(id) {
      this.btnEliminarCostoAnualDisabled = true;
      await this.$store
        .dispatch(ELIMINAR_PRESUPUESTO_ANUAL, id)
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerCostosAnuales(this.iniciativaId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarCostoAnualDisabled = false;
        })
        .catch((error) => {
          this.btnEliminarCostoAnualDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },
  },

  created() {
    this.obtenerPresupuestoGeneral(this.iniciativaId);
    this.obtenerPaisRol(0);
    this.obtenerRoles().then(() => {
      this.obtenerPeriodos();
      this.obtenerTiposAporte();
      /*  this.obtenerPaises().then(()=>{
                
            }); */
    });
  },

  watch: {
    tipoCambioCostosGenerales: function () {
      this.tipoCambioCostosGenerales.length > 0 &&
      this.costosPresupuestadosD.length > 0
        ? (this.costosPresupuestadosQ = (
            this.costosPresupuestadosD * this.tipoCambioCostosGenerales
          )
            .toFixed(2)
            .toString())
        : (this.costosPresupuestadosQ = "");
    },

    costosPresupuestadosD: function () {
      this.tipoCambioCostosGenerales.length > 0 &&
      this.costosPresupuestadosD.length > 0
        ? (this.costosPresupuestadosQ = (
            this.costosPresupuestadosD * this.tipoCambioCostosGenerales
          )
            .toFixed(2)
            .toString())
        : (this.costosPresupuestadosQ = "");
    },

    tipoCambioCostosAnuales: function () {
      this.tipoCambioCostosAnuales.length > 0 &&
      this.presupuestoAnualD.length > 0
        ? (this.presupuestadosAnualQ = (
            this.presupuestoAnualD * this.tipoCambioCostosAnuales
          )
            .toFixed(2)
            .toString())
        : (this.presupuestadosAnualQ = "");
    },

    presupuestoAnualD: function () {
      this.tipoCambioCostosAnuales.length > 0 &&
      this.presupuestoAnualD.length > 0
        ? (this.presupuestadosAnualQ = (
            this.presupuestoAnualD * this.tipoCambioCostosAnuales
          )
            .toFixed(2)
            .toString())
        : (this.presupuestadosAnualQ = "");
    },
  },

  computed: {
    headersCostosPresupuestados() {
      return [
        {
          text: "Id",
          align: "d-none",
          sortable: false,
          value: "id",
        },
        {
          text: "Rol",
          align: "start",
          sortable: false,
          value: "Rol",
        },
        {
          text: "Actor",
          align: "start",
          sortable: false,
          value: "actor",
        },
        {
          text: "Tipo Aporte",
          align: "start",
          sortable: false,
          value: "aporte",
        },
        {
          text: "Fuente Financiera",
          align: "start",
          sortable: false,
          value: "fuenteFinanciera",
        },
        {
          text: "Costos GTQ/USDM",
          align: "start",
          sortable: true,
          value: "costoQuetzales",
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estado",
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "",
        },
      ];
    },

    headersCostosAnuales() {
      return [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Periodo",
          align: "start",
          sortable: false,
          value: "periodo",
        },
        {
          text: "Rol",
          align: "start",
          sortable: false,
          value: "rolActor",
        },
        {
          text: "Actor",
          align: "start",
          sortable: false,
          value: "actor",
        },
        {
          text: "Tipo / Fecha cambio",
          align: "start",
          sortable: false,
          value: "aporte",
        },
        {
          text: "Costos GTQ / USDM",
          align: "start",
          sortable: false,
          value: "fuenteFinanciera",
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estado",
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "",
        },
      ];
    },

    headersTiposAporte() {
      return [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Tipo de Aporte",
          align: "start",
          sortable: false,
          value: "anio",
        },

        {
          text: "Nombre de la fuente",
          align: "start",
          sortable: false,
          value: "fuente",
        },
        {
          text: "Rol",
          align: "start",
          sortable: false,
          value: "Rol",
        },
        {
          text: "Actor",
          align: "start",
          sortable: false,
          value: "actor",
        },
        {
          text: "Costo Quetzales",
          align: "start",
          sortable: true,
          value: "costoQuetzales",
        },
        {
          text: "Costo Dolares",
          align: "start",
          sortable: true,
          value: "costoDolares",
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "",
        },
      ];
    },
  },
};
</script>
