var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.skeletonLoading)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-skeleton-loader',_vm._b({attrs:{"min-height":"600px","type":"article, article, article, actions"}},'v-skeleton-loader',_vm.attrs,false))],1)],1)],1):_vm._e(),(!_vm.skeletonLoading)?_c('div',[_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0 mt-4 pb-0 mb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text bold--text"},[_vm._v(" Articulaciones ")])],1)],1),(_vm.modalidadId!=3)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"text":"","type":"warning","color":"orange darken-1","icon":"mdi-alert"}},[_vm._v(" Las articulaciones solo son requeridas para las negociaciones en modalidad Triangular! ")])],1)],1):_vm._e(),(_vm.modalidadId===3)?_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.registrarItem.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',{staticClass:"mt-6"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"dense":"","filled":"","items":_vm.marcosCooperacion,"color":"blue-grey lighten-2","label":"Marco en el que se ampara la cooperación","item-text":"nombreMarco","item-value":"id","menu-props":"auto","rules":[
                                    _vm.modalidadId===3 ? _vm.selectRequired('marco en el que se ampara la cooperacion') : true ]},model:{value:(_vm.datosItem.marcoCooperacionSIDICSSId),callback:function ($$v) {_vm.$set(_vm.datosItem, "marcoCooperacionSIDICSSId", $$v)},expression:"datosItem.marcoCooperacionSIDICSSId"}})],1)],1),(_vm.modalidadId===3)?_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"dense":"","filled":"","items":_vm.origenesIniciativa,"color":"blue-grey lighten-2","label":"¿Cómo se origina la iniciativa?","item-text":"origen","item-value":"id","menu-props":"auto","rules":[
                                    _vm.modalidadId == 3 ? _vm.selectRequired('origen de la iniciativa') : true ]},model:{value:(_vm.datosItem.originaIniciativaId),callback:function ($$v) {_vm.$set(_vm.datosItem, "originaIniciativaId", $$v)},expression:"datosItem.originaIniciativaId"}})],1)],1):_vm._e(),(_vm.modalidadId===3)?_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"dense":"","filled":"","items":_vm.procedimientosItems,"color":"blue-grey lighten-2","label":"Indique si se han establecido procedimientos/órganos para la toma de decisiones consensuadas entre todos los socios","item-text":"text","item-value":"id","menu-props":"auto","return-object":"","rules":[
                                    _vm.modalidadId == 3 ? _vm.selectRequired('procedimientos') : true ]},model:{value:(_vm.estableceProcedimientoSeleccionado),callback:function ($$v) {_vm.estableceProcedimientoSeleccionado=$$v},expression:"estableceProcedimientoSeleccionado"}})],1),(_vm.estableceProcedimientoSeleccionado.id === 1)?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","filled":"","rows":"3","color":"blue-grey lighten-2","label":"Especifique los procedimientos/órganos","rules":[
                                    _vm.modalidadId == 3 && _vm.estableceProcedimientoSeleccionado.id === 1 ? _vm.required('procedimientos') : true,
                                    _vm.modalidadId == 3 && _vm.estableceProcedimientoSeleccionado.id === 1 ? _vm.maxLength('procedimientos', 1000) : true,
                                    _vm.modalidadId == 3 && _vm.estableceProcedimientoSeleccionado.id === 1 ? _vm.minLength('procedimientos', 10) : true
                                ]},model:{value:(_vm.datosItem.especificacionProcOrganos),callback:function ($$v) {_vm.$set(_vm.datosItem, "especificacionProcOrganos", $$v)},expression:"datosItem.especificacionProcOrganos"}})],1):_vm._e()],1):_vm._e(),(_vm.modalidadId===3)?_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"dense":"","filled":"","items":_vm.mecanismosSegItems,"color":"blue-grey lighten-2","label":"Indique si se han establecido mecanismos de seguimiento y evaluación","item-text":"text","item-value":"id","menu-props":"auto","return-object":"","rules":[
                                    _vm.modalidadId == 3 ? _vm.selectRequired('procedimientos') : true ]},model:{value:(_vm.mecanismoSegSeleccionado),callback:function ($$v) {_vm.mecanismoSegSeleccionado=$$v},expression:"mecanismoSegSeleccionado"}})],1)],1):_vm._e(),(_vm.modalidadId===3)?_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"dense":"","filled":"","items":_vm.mecanismosEvaluacion,"color":"blue-grey lighten-2","label":"Señale los mecanismos de evaluación que se han implementado","item-text":"mecanismo","item-value":"id","menu-props":"auto","rules":[
                                    _vm.modalidadId == 3 ?  _vm.selectRequired('mecanismo de evaluación') : true ]},model:{value:(_vm.datosItem.mecanismosEvaluacionSIDICSSId),callback:function ($$v) {_vm.$set(_vm.datosItem, "mecanismosEvaluacionSIDICSSId", $$v)},expression:"datosItem.mecanismosEvaluacionSIDICSSId"}})],1)],1):_vm._e(),(_vm.modalidadId===2)?_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"dense":"","filled":"","items":_vm.organismosCooperacion,"color":"blue-grey lighten-2","label":"Organismo, esquema intergubernamental o similar que acompaña esta cooperación","item-text":"nombre","item-value":"id","menu-props":"auto","rules":[
                                    _vm.modalidadId == 2 ? _vm.selectRequired('mecanismo de evaluación') : true ]},model:{value:(_vm.datosItem.organismoEsquemaCooperacionId),callback:function ($$v) {_vm.$set(_vm.datosItem, "organismoEsquemaCooperacionId", $$v)},expression:"datosItem.organismoEsquemaCooperacionId"}})],1)],1):_vm._e(),(_vm.modalidadId===2)?_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"dense":"","filled":"","items":_vm.mecanismosCooperacion,"color":"blue-grey lighten-2","label":"Indique el mecanismo de cooperación bajo el que tiene lugar la iniciativa","item-text":"mecanismo","item-value":"id","menu-props":"auto","rules":[
                                    _vm.modalidadId == 2 ? _vm.selectRequired('mecanismo de cooperación') : true ]},model:{value:(_vm.datosItem.mecanismoCooperacionSIDICSSId),callback:function ($$v) {_vm.$set(_vm.datosItem, "mecanismoCooperacionSIDICSSId", $$v)},expression:"datosItem.mecanismoCooperacionSIDICSSId"}})],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 float-right",attrs:{"color":"primary","type":"submit","elevation":0,"disabled":!_vm.validForm,"loading":_vm.btnRegistroLoading}},[_vm._v(" Guardar información ")])],1)],1)],1):_vm._e()],1)],1)],1):_vm._e(),_c('DialogLoader',{attrs:{"dialogVisible":_vm.dialogLoaderVisible,"text":_vm.dialogLoaderText,"transition":"scroll-y-transition"}}),_c('SnackAlert',{ref:"snackalert"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }