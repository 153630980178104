<template>
  <div>
    <div v-if="skeletonLoading">
      <v-row>
        <v-col cols="12" md="12">
          <v-skeleton-loader
            v-bind="attrs"
            min-height="600px"
            type="article, article, article, actions"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <div v-if="!skeletonLoading">
      <v-row>
        <!--inicio:: campo -->
        <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
          <v-select
            dense
            filled
            v-model="datosItem.tipoActividadId"
            :items="tiposActividad"
            class="required"
            color="blue-grey lighten-2"
            label="Tipo de actividad"
            item-text="tipo"
            item-value="id"
            :rules="[selectRequired('Modalidad')]"
            menu-props="auto"
          >
          </v-select>
        </v-col>
        <!--fin:: campo-->

        <!--inicio:: campo -->
        <!--  <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
        <v-select
          dense
          filled
          v-model="datosItem.sectoresId"
          :items="sectores"
          class="required"
          color="blue-grey lighten-2"
          label="Sector"
          item-text="sector"
          item-value="id"
          :rules="[selectRequired('Modalidad')]"
          menu-props="auto"
          
        >
        </v-select>
      </v-col> -->
        <!--fin:: campo-->
      </v-row>

      <v-row>
        <!--inicio:: campo-->
        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
          <v-text-field
            dense
            filled
            autocomplete="off"
            class="required"
            color="blue-grey lighten-2"
            v-model="fechaPrevista"
            label="Fecha prevista de la actividad"
            :rules="[
              required('Fecha prevista'),
              minLength('Fecha prevista', 8),
              dateFormat('Fecha prevista'),
            ]"
            maxlength="50"
            v-mask="'##/##/####'"
          ></v-text-field>
        </v-col>
        <!--fin:: campo-->

        <!--inicio:: campo -->
        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
          <v-autocomplete
            dense
            filled
            v-model="paisActividadSeleccionado"
            :items="paises"
            class="required"
            color="blue-grey lighten-2"
            label="Lugar de la actividad (País)"
            :item-text="(item) => `${item.rol} | ${item.nombre}`"
            item-value="id"
            :rules="[selectRequired('país')]"
            menu-props="auto"
            return-object
          >
          </v-autocomplete>
        </v-col>
        <!--fin:: campo-->
      </v-row>

      <v-row>
        <!--inicio:: campo -->
        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
          <v-textarea
            autocomplete="off"
            class="required"
            dense
            filled
            color="blue-grey lighten-2"
            v-model="datosItem.descripcionActividad"
            label="Descripción de la actividad"
            rows="3"
            :rules="[
              required('desripción de la actividad'),
              minLength('desripción de la actividad', 5),
              maxLength('desripción de la actividad', 200),
            ]"
            maxlength="200"
          ></v-textarea>
        </v-col>
        <!--fin:: campo-->

        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
          <v-spacer></v-spacer>
          <v-btn
            class="ma-0 white--text float-right"
            medium
            color="light-blue darken-2"
            @click="agregarActividad"
            :loading="btnRegistroLoading"
          >
            <v-icon left>mdi-plus</v-icon> Agregar actividad
          </v-btn>
        </v-col>

        <v-col cols="12" md="12" sm="12">
          <v-subheader class="text-button">
            Actividades registradas
          </v-subheader>
          <v-divider class="mt-0 pt-0"></v-divider>
          <template>
            <v-data-table
              :headers="headersActividades"
              :items="actividades"
              :items-per-page="5"
              :header-props="{
                sortByText: 'Ordenar por',
              }"
              no-data-text="No se encontraron registros"
              loading-text="Cargando..."
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                'items-per-page-text': 'Registros por página',
                pageText: '{0}-{1} de {2}',
              }"
              class="elevation-1"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.descripcionActividad }}</td>
                  <td>{{ item.tipo }}</td>
                  <!--  <td>{{ item.sector }}</td> -->
                  <td>{{ item.fechaPrevista | formatDate }}</td>
                  <td>{{ item.pais }}</td>
                  <td>
                    <v-chip
                      class="ma-2 font-weight-medium"
                      label
                      :color="
                        item.estadoId === 1
                          ? 'blue-grey lighten-4'
                          : item.estadoId === 2
                          ? 'blue lighten-4'
                          : item.estadoId === 6
                          ? 'cyan lighten-4'
                          : item.estadoId === 7
                          ? 'deep-orange lighten-4'
                          : 'pink lighten-4'
                      "
                      :text-color="
                        item.estadoId === 1
                          ? 'blue-grey lighten-1'
                          : item.estadoId === 2
                          ? 'blue lighten-1'
                          : item.estadoId === 6
                          ? 'cyan darken-1'
                          : item.estadoId === 7
                          ? 'deep-orange lighten-1'
                          : 'pink darken-1'
                      "
                      small
                    >
                      {{ item.estado }}
                    </v-chip>
                  </td>
                  <td>
                    <v-btn
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      :disabled="btnEliminarActividadDisabled"
                      color="blue-grey lighten-5"
                      @click="eliminarActividad(item.id)"
                    >
                      <v-icon left>mdi-delete</v-icon> Eliminar
                    </v-btn>

                    <v-btn
                      v-if="item.estadoId === 1"
                      :disabled="btnEliminarActividadDisabled"
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      color="blue-grey lighten-5"
                      @click="
                        mostrarFormularioProductos(
                          item.id,
                          item.descripcionActividad
                        )
                      "
                    >
                      <v-icon left>mdi-plus</v-icon> Agregar Resultados
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </template>
        </v-col>
      </v-row>

      <v-card outlined v-if="formProducto" class="mt-4">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
              <v-subheader class="text-h6 black--text">
                Agregar resultados de la actividad | {{ actividadSeleccionada }}
              </v-subheader>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-form
            ref="form"
            v-on:submit.prevent="registrarProducto"
            v-model="validFormProducto"
          >
            <v-row>
              <v-col cols="12" md="8" sm="12">
                <v-textarea
                  dense
                  filled
                  rows="3"
                  v-model="datosProducto.producto"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Resultado"
                  :rules="[required('Resultado'), maxLength('Resultado', 500)]"
                  maxLength="500"
                >
                </v-textarea>
              </v-col>

              <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                <v-spacer></v-spacer>
                <v-btn
                  class="ma-0 white--text float-right mt-3"
                  medium
                  type="submit"
                  color="light-blue darken-2"
                  :disabled="!validFormProducto"
                  :loading="btnRegistroProductoLoading"
                >
                  <v-icon left>mdi-plus</v-icon> Agregar resultado
                </v-btn>
              </v-col>
            </v-row>
          </v-form>

          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12">
              <v-subheader class="text-subitle-1 black--text">
                Resultados de la actividad
              </v-subheader>
              <v-divider class="mt-0 pt-0"></v-divider>
              <template>
                <v-data-table
                  :headers="headersProductos"
                  :items="productos"
                  :items-per-page="5"
                  :loading="tableProductosLoading"
                  :header-props="{
                    sortByText: 'Ordenar por',
                  }"
                  no-data-text="No se encontraron registros"
                  loading-text="Cargando..."
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text': 'Registros por página',
                    pageText: '{0}-{1} de {2}',
                  }"
                  class="elevation-1"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.producto }}</td>
                      <td>
                        <v-chip
                          class="ma-2 font-weight-medium"
                          label
                          :color="
                            item.estadosId === 1
                              ? 'blue lighten-4'
                              : item.estadosId === 2
                              ? 'cyan lighten-4'
                              : item.estadosId === 6
                              ? 'cyan lighten-4'
                              : item.estadosId === 7
                              ? 'deep-orange lighten-4'
                              : 'pink lighten-4'
                          "
                          :text-color="
                            item.estadosId === 1
                              ? 'blue lighten-1'
                              : item.estadosId === 2
                              ? 'cyan darken-1'
                              : item.estadosId === 6
                              ? 'cyan darken-1'
                              : item.estadosId === 7
                              ? 'deep-orange lighten-1'
                              : 'pink darken-1'
                          "
                          small
                        >
                          {{ item.estado }}
                        </v-chip>
                      </td>
                      <td>
                        <v-btn
                          class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                          small
                          depressed
                          color="blue-grey lighten-5"
                          @click="eliminarProducto(item.id)"
                          :disabled="btnEliminarProductoDisabled"
                        >
                          <v-icon left>mdi-delete</v-icon> Eliminar
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!--Inicio:: Snack alert-->
      <SnackAlert ref="snackalert"></SnackAlert>
      <!-- Fin:: Snack alert-->
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment";

import SnackAlert from "@/view/content/alerts/SnackAlert.vue";

import validations from "@/core/untils/validations.js";
import {
  OBTENER_ACTIVIDADES,
  REGISTRAR_ACTIVIDAD,
  ELIMINAR_ACTIVIDAD,
} from "@/core/services/store/negociaciones/actividadiniciativa.module";
import {
  REGISTRAR_PRODUCTO_ACTIVIDAD,
  OBTENER_PRODUCTOS_ACTIVIDAD,
  ELIMINAR_PRODUCTO_ACTIVIDAD,
} from "@/core/services/store/negociaciones/iniciativas/productosactividades/productoactividad.module";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
//import { OBTENER_PAISES } from "@/core/services/store/catalogos/paises/pais.module";
moment.locale("es");
Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

export default {
  name: "RegistroDirectaActividades",
  components: {
    SnackAlert,
  },
  props: ["iniciativaId", "negociacionId"], //Representa el id de la iniciativa
  data() {
    return {
      validFormProducto: false,
      skeletonLoading: false,
      tiposActividad: [],
      tableProductosLoading: false,
      sectores: [],
      paises: [],
      actividades: [],
      productos: [],
      btnRegistroLoading: false,
      btnRegistroProductoLoading: false,
      btnEliminarActividadDisabled: false,
      btnEliminarProductoDisabled: false,
      formProducto: false,
      fechaPrevista: "",
      actividadSeleccionada: "",
      datosItem: {
        fechaPrevista: "",
        descripcionActividad: "",
        iniciativaId: this.iniciativaId,
        tipoActividadId: 0,
        paisId: 0,
        estadosId: 1,
        //sectoresId: 0,
        usuarioCreacion: "admin",
      },
      datosProducto: {
        producto: "",
        estadosId: 1,
        actividadesIniciativaId: 0,
        usuarioCreacion: "admin",
      },
      paisActividadSeleccionado: {},
      ...validations,
    };
  },

  methods: {
    resetItems() {
      this.fechaPrevista = "";
      this.paisActividadSeleccionado = {};
      this.datosItem = {
        fechaPrevista: "",
        descripcionActividad: "",
        iniciativaId: this.iniciativaId,
        tipoActividadId: 0,
        paisId: 0,
        estadosId: 1,
        //sectoresId: 0,
        usuarioCreacion: "admin",
      };
      this.actividades = [];
    },
    //Obtener el listado de actividades segun la iniciativa
    async obtenerActividades(idIniciativa) {
      this.actividades = [];
      this.skeletonLoading = true;

      this.$store
        .dispatch(OBTENER_ACTIVIDADES, {
          estadoId: 0,
          iniciativaId: idIniciativa,
        })
        .then(() => {
          this.actividades = this.$store.state.actividadiniciativa.actividades;
          this.skeletonLoading = false;
        })
        .catch(() => {
          this.skeletonLoading = false;
        });
    },

    async obtenerTiposActividad() {
      this.tiposActividad = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "TipoActividad/all/1" })
        .then((res) => {
          if (res.status === 200) {
            this.tiposActividad = res.data;
          }
          // this.skeletonLoading=false;
        })
        .catch(() => {
          this.tiposActividad = [];
          // this.skeletonLoading=false;
        });
    },

    async obtenerSectores() {
      this.sectores = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Sectores/all/1" })
        .then((res) => {
          if (res.status === 200) {
            this.sectores = res.data;
          }
          // this.skeletonLoading=false;
        })
        .catch(() => {
          this.sectores = [];
          // this.skeletonLoading=false;
        });
    },

    async obtenerPaises() {
      //console.log("asfsd")
      this.paises = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `Pais/Actores/${this.negociacionId}`,
        })
        .then((res) => {
          if (res.status === 200) {
            this.paises = res.data;
          }
          // this.skeletonLoading=false;
        })
        .catch(() => {
          this.paises = [];
          // this.skeletonLoading=false;
        });
    },

    async agregarActividad() {
      this.datosItem.fechaPrevista = moment(
        this.fechaPrevista,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");

      delete this.datosItem.organismosActoresId;
      delete this.datosItem.paisId;

      if (this.paisActividadSeleccionado.grupo === "Organismo")
        this.datosItem.organismosActoresId = this.paisActividadSeleccionado.id;
      else this.datosItem.paisId = this.paisActividadSeleccionado.id;

      this.btnRegistroLoading = true;
      //this.datosRiesgo.proyectoCNSId = this.id;
      await this.$store
        .dispatch(REGISTRAR_ACTIVIDAD, this.datosItem)
        .then((res) => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            //  this.switchItemEstado = true;
            //this.datosRiesgo.detalleRiesgo = '';
            this.resetItems();
            this.btnRegistroLoading = false;
            this.obtenerActividades(this.iniciativaId);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch((error) => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnRegistroLoading = false;
        });
    },

    //Eliminar una actividad
    async eliminarActividad(id) {
      this.btnEliminarActividadDisabled = true;

      this.$store
        .dispatch(ELIMINAR_ACTIVIDAD, id)
        .then((res) => {
          if (res.status === 200) {
            this.productos = res.data;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerActividades(this.datosItem.iniciativaId);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }

          this.btnEliminarActividadDisabled = false;
          this.tableProductosLoading = false;
        })
        .catch((error) => {
          this.btnEliminarActividadDisabled = false;
          this.tableProductosLoading = false;
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
        });
    },

    //Mostrar formulario de registro de productos para la actividad
    mostrarFormularioProductos(id, actividad) {
      this.datosProducto.actividadesIniciativaId = id;
      this.actividadSeleccionada = actividad;
      this.formProducto = true;
      this.obtenerProductos(id);
    },

    //Registrar producto de la actividad
    async registrarProducto() {
      this.btnRegistroProductoLoading = true;

      await this.$store
        .dispatch(REGISTRAR_PRODUCTO_ACTIVIDAD, this.datosProducto)
        .then((res) => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.datosProducto.producto = "";
            //  this.switchItemEstado = true;
            //this.datosRiesgo.detalleRiesgo = '';
            //this.resetItems();
            this.obtenerProductos(this.datosProducto.actividadesIniciativaId);
            this.btnRegistroProductoLoading = false;
            //this.obtenerActividades(this.iniciativaId);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroProductoLoading = false;
        })
        .catch((error) => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnRegistroProductoLoading = false;
        });
    },

    //Obtener productos de una actividad
    async obtenerProductos(actividadId) {
      this.tableProductosLoading = true;
      this.productos = [];
      this.$store
        .dispatch(OBTENER_PRODUCTOS_ACTIVIDAD, {
          estadoId: 1,
          actividadId: actividadId,
        })
        .then((res) => {
          if (res.status === 200) {
            this.productos = res.data;
          }
          this.tableProductosLoading = false;
        })
        .catch(() => {
          this.productos = [];
          this.tableProductosLoading = false;
        });
    },

    //Eliminar un producto
    async eliminarProducto(id) {
      this.btnEliminarProductoDisabled = true;
      this.tableProductosLoading = true;
      this.$store
        .dispatch(ELIMINAR_PRODUCTO_ACTIVIDAD, id)
        .then((res) => {
          if (res.status === 200) {
            this.productos = res.data;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerProductos(this.datosProducto.actividadesIniciativaId);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }

          this.btnEliminarProductoDisabled = false;
          this.tableProductosLoading = false;
        })
        .catch((error) => {
          this.btnEliminarProductoDisabled = false;
          this.tableProductosLoading = false;
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
        });
    },
  },

  created() {
    this.obtenerTiposActividad();
    this.obtenerSectores();
    this.obtenerPaises();

    this.obtenerActividades(this.iniciativaId);
  },

  computed: {
    headersActividades() {
      return [
        {
          text: "Descripcion",
          align: "start",
          sortable: false,
          value: "descripcionActividad",
        },
        {
          text: "Tipo",
          align: "start",
          sortable: false,
          value: "tipo",
        },
        /* {
          text: "Sector",
          align: "start",
          sortable: true,
          value: "sector"
        }, */
        {
          text: "Fecha prevista",
          align: "start",
          sortable: true,
          value: "fechaPrevista",
        },
        {
          text: "Lugar",
          align: "start",
          sortable: true,
          value: "pais",
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estado",
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "",
        },
      ];
    },

    headersProductos() {
      return [
        {
          text: "Resultado",
          align: "start",
          sortable: true,
          value: "producto",
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estado",
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "",
        },
      ];
    },
  },
};
</script>
<style lang=""></style>
