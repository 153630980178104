<template>
  <div>
    <div v-if="skeletonLoading">
      <v-row>
        <v-col cols="12" md="12">
          <v-skeleton-loader
            min-height="600px"
            type="article, article, article, actions"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <div v-if="!skeletonLoading">
      <!--inicio:: condiciones de instituciones beneficiarias -->
      <v-card class="mt-6" outlined>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-h6 black--text">
                Datos de los contactos
              </v-subheader>

              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>

            <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
              <v-form
                ref="formProyeccionDesembolsos"
                v-on:submit.prevent="registrarContactos"
                v-model="validForm"
              >
                <v-row>
                  <!--inicio:: campo -->
                  <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                    <v-select
                      dense
                      filled
                      v-model="tipoContactoSeleccionado"
                      :items="tiposContacto"
                      class="required"
                      color="blue-grey lighten-2"
                      label="Seleccione el tipo de contacto"
                      item-text="tipo"
                      item-value="id"
                      :rules="[selectRequired('tipo de contacto')]"
                      @change="configurarDatosContacto"
                      menu-props="auto"
                      return-object
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                    <v-text-field
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      v-model="datosContacto.nombre"
                      label="Nombre del contacto"
                      :rules="[
                        required('nombre del contacto'),
                        minLength('nombre del contacto', 5),
                        maxLength('nombre del contacto', 100),
                      ]"
                      maxlength="100"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                    <v-select
                      dense
                      filled
                      v-model="datosContacto.cargosId"
                      :items="cargos"
                      class="required"
                      color="blue-grey lighten-2"
                      :loading="false"
                      label="Cargo"
                      item-text="nombreCargo"
                      item-value="id"
                      :rules="[
                        tipoContactoSeleccionado.id === 2
                          ? selectRequired('cargo')
                          : true,
                      ]"
                      menu-props="auto"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                    <v-text-field
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      v-model="datosContacto.area"
                      label="Área a la que pertenece el contacto"
                      :rules="[
                        required('área'),
                        minLength('área', 5),
                        maxLength('área', 100),
                      ]"
                      maxlength="100"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                    <v-text-field
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      v-model="datosContacto.correoElectronicoInstitucional"
                      label="Correo Institucional"
                      :rules="[
                        required('correo  Institucional'),
                        minLength('correo  Institucional', 5),
                        maxLength('correo  Institucional', 150),
                        validEmail('correo  Institucional'),
                      ]"
                      maxlength="150"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                    <v-text-field
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      v-model="datosContacto.telefonoInstitucional"
                      label="Teléfono institucional"
                      :rules="[
                        required('teléfono institucional'),
                        minLength('teléfono institucional', 8),
                        maxLength('teléfono institucional', 15),
                      ]"
                      maxlength="15"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                    <v-text-field
                      dense
                      filled
                      autocomplete="off"
                      color="blue-grey lighten-2"
                      v-model="datosContacto.telefonoPersonal"
                      label="Teléfono personal"
                      :rules="[maxLength('teléfono personal', 15)]"
                      maxlength="15"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" sm="12" class="pt-0">
                    <v-btn
                      color="light-blue-502"
                      class="white--text mb-2 float-right"
                      type="submit"
                      :elevation="0"
                      :disabled="!validForm"
                      :loading="btnRegistroLoading"
                    >
                      Guardar contacto
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12">
              <v-subheader class="text-button">
                Contactos registrados
              </v-subheader>
              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <!--inicio:: tabla de documentos cargados -->
            <v-col cols="12" md="12" sm="12" class="pt-4">
              <v-data-table
                class="elevation-1"
                :headers="headersContactos"
                :items="contactos"
                :loading="tableContactosLoading"
                :header-props="{
                  sortByText: 'Ordenar por',
                }"
                no-data-text="No se encontraron registros"
                loading-text="Cargando..."
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}',
                }"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.tipo }}</td>
                    <td>{{ item.nombre }}</td>
                    <td>{{ item.area }}</td>
                    <td>{{ item.nombreCargo }}</td>
                    <td>
                      <small
                        class="text-subtitle-2"
                        v-if="item.correoElectronicoPersonal"
                        >personal: {{ item.correoElectronicoPersonal }}<br
                      /></small>
                      <small
                        class="text-subtitle-2"
                        v-if="item.correoElectronicoInstitucional"
                        >institucional:
                        {{ item.correoElectronicoInstitucional }}
                      </small>
                    </td>
                    <td>
                      <small
                        class="text-subtitle-2"
                        v-if="item.telefonoPersonal"
                        >personal: {{ item.telefonoPersonal }} <br
                      /></small>
                      <small
                        class="text-subtitle-2"
                        v-if="item.telefonoInstitucional"
                        >institucional: {{ item.telefonoInstitucional }}
                      </small>
                    </td>
                    <td>
                      <v-chip
                        class="ma-2 font-weight-medium"
                        label
                        :color="
                          item.estadosId === 1
                            ? 'blue lighten-4'
                            : item.estadosId === 2
                            ? 'deep-orange lighten-4'
                            : item.estadosId === 6
                            ? 'cyan lighten-4'
                            : item.estadosId === 7
                            ? 'deep-orange lighten-4'
                            : 'pink lighten-4'
                        "
                        :text-color="
                          item.estadosId === 1
                            ? 'blue lighten-1'
                            : item.estadosId === 2
                            ? 'deep-orange lighten-1'
                            : item.estadosId === 6
                            ? 'cyan darken-1'
                            : item.estadosId === 7
                            ? 'deep-orange lighten-1'
                            : 'pink darken-1'
                        "
                        small
                      >
                        {{ item.estado }}
                      </v-chip>
                    </td>
                    <td>
                      <v-btn
                        v-if="item.estadosId == 1"
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        depressed
                        :disabled="btnEliminarDisabled"
                        color="blue-grey lighten-5"
                        @click="eliminarContacto(item.id)"
                      >
                        <v-icon left>mdi-delete</v-icon> Dar de baja
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>

              <v-row
                class="mt-4"
                v-if="
                  cantidadEnlace === 0 ||
                  cantidadResponsableEjecucion === 0 ||
                  cantidadResponsableSeguimiento === 0
                "
              >
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-alert outlined type="error" color="red darken-2" dense>
                    Debe completar el registro con al menos un contacto para
                    cada tipo.
                  </v-alert>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <SnackAlert ref="snackalert"></SnackAlert>
      <!-- Fin:: Snack alert-->

      <!--Dialog loader -->
      <DialogLoader
        :dialogVisible="dialogLoaderVisible"
        :text="dialogLoaderText"
      ></DialogLoader>
      <!---->
    </div>
  </div>
</template>


<script>
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
import {
  OBTENER_CONTACTOS,
  REGISTRAR_CONTACTO,
  ELIMINAR_CONTACTO,
} from "@/core/services/store/contactos/contacto.module";

export default {
  name: "SeccionContactosDirecta",
  components: {
    DialogLoader,
    SnackAlert,
  },
  props: ["iniciativaId"],
  data() {
    return {
      skeletonLoading: false,
      validForm: false,
      btnRegistroLoading: false,
      dialogLoaderVisible: false,
      btnEliminarDisabled: false,
      dialogLoaderText: "",
      tableContactosLoading: false,
      tiposContacto: [],
      tipoContactoSeleccionado: {},
      cargos: [],
      contactos: [],
      datosContacto: {
        nombre: "",
        cargosId: null,
        area: "",
        correoElectronicoPersonal: null,
        telefonoPersonal: "",
        tipo: 2,
        correoElectronicoInstitucional: "",
        telefonoInstitucional: "",
        estadosId: 1,
        usuarioCreacion: "admin",
      },
      cantidadEnlace: 0,
      cantidadResponsableEjecucion: 0,
      cantidadResponsableSeguimiento: 0,
      cantidad4: 0,
      ...validations,
    };
  },

  methods: {
    //Restablecer los campos
    resetItems(tipo) {
      if (tipo === 2) {
        this.skeletonLoading = true;
      }
      this.contactos = [];
      this.tipoContactoSeleccionado = {};

      this.datosContacto = {
        nombre: "",
        cargosId: null,
        area: "",
        tipo: 2,
        correoElectronicoPersonal: null,
        telefonoPersonal: "",
        correoElectronicoInstitucional: "",
        telefonoInstitucional: "",
        estadosId: 1,
        usuarioCreacion: "admin",
      };
    },

    //Obtener los tipos de contacto
    async obtenerTiposContacto() {
      this.tiposContacto = [];
      this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "TipoContacto/all/1/1" })
        .then((res) => {
          if (res.status === 200) {
            this.tiposContacto = res.data;
          }
        })
        .catch(() => {
          this.tiposContacto = [];
        });
    },

    //Obtener los cargos
    async obtenerCargos() {
      this.cargos = [];
      this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Cargos/all/1" })
        .then((res) => {
          if (res.status === 200) {
            this.cargos = res.data;
          }
        })
        .catch(() => {
          this.cargos = [];
        });
    },

    //Obtener la información de contactos del proyecto
    async obtenerContactos(iniciativaId) {
      this.contactos = [];

      this.$store
        .dispatch(OBTENER_CONTACTOS, {
          tipoContacto: "INICIATIVA",
          estadoId: 1,
          id: iniciativaId,
        })
        .then((res) => {
          if (res.status === 200) {
            this.contactos = res.data;

            let arrTipoEnlace = this.contactos.filter(
              (item) => item.tipoContactoId === 4
            );
            let arrTipoResEjec = this.contactos.filter(
              (item) => item.tipoContactoId === 6
            );
            let arrTipoResSeg = this.contactos.filter(
              (item) => item.tipoContactoId === 5
            );

            this.cantidadEnlace =
              arrTipoEnlace != null ? arrTipoEnlace.length : 0;
            this.cantidadResponsableEjecucion =
              arrTipoResEjec != null ? arrTipoResEjec.length : 0;
            this.cantidadResponsableSeguimiento =
              arrTipoResSeg != null ? arrTipoResSeg.length : 0;
          }
          this.skeletonLoading = false;
        })
        .catch(() => {
          this.skeletonLoading = false;
        });
    },

    //Configurar los contactos en base al tipo
    configurarDatosContacto() {},

    async registrarContactos() {
      this.btnRegistroLoading = true;
      this.datosContacto.tipo = 2;
      this.datosContacto.estadosId = 1;
      this.datosContacto.usuarioCreacion = "admin";
      this.datosContacto.tipoContactoId = this.tipoContactoSeleccionado.id;
      this.datosContacto.iniciativaId = this.iniciativaId;

      await this.$store
        .dispatch(REGISTRAR_CONTACTO, { datos: this.datosContacto })
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerContactos(this.iniciativaId);
            this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch((error) => {
          this.btnRegistroLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    async eliminarContacto(id) {
      this.btnEliminarDisabled = true;
      await this.$store
        .dispatch(ELIMINAR_CONTACTO, id)
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerContactos(this.iniciativaId);
            this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarDisabled = false;
        })
        .catch((error) => {
          this.btnEliminarDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },
  },

  created() {
    this.obtenerCargos().then(() => {
      this.obtenerTiposContacto().then(() => {
        this.obtenerContactos(this.iniciativaId);
      });
    });
  },

  computed: {
    headersContactos() {
      return [
        {
          text: "Id",
          //align: "start",
          sortable: false,
          value: "id",
          align: " d-none",
        },
        {
          text: "Tipo Contacto",
          align: "start",
          sortable: true,
          value: "tipo",
        },
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "nombre",
        },
        {
          text: "Área",
          align: "start",
          sortable: true,
          value: "area",
        },
        {
          text: "Cargo",
          align: "start",
          sortable: true,
          value: "cargo",
        },
        {
          text: "Correo(s)",
          align: "start",
          sortable: true,
          value: "correo",
        },
        {
          text: "Teléfono(s)",
          align: "start",
          sortable: true,
          value: "telefono",
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estado",
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "",
        },
      ];
    },
  },
};
</script>