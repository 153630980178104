<template lang="">
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <span class="card-label font-weight-bolder text-dark">
              Iniciativas </span
            ><br />
            <span class="text-muted mt-3 font-weight-bold font-size-md">
              {{ datosNegociacion.titulo }}
            </span>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-btn
              color="light-blue-502"
              dark
              class="white--text mb-2 float-right"
              @click="modalNuevo"
            >
              Registrar iniciativa
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text>
        <div class="separator separator-solid"></div>

        <!--incio:: proyecto -->
        <v-row>
          <v-col cols="12">
            <v-data-table
              class="elevation-0"
              :headers="headers"
              :items="items"
              :search="filtro"
              :loading="tableLoading"
              :header-props="{
                sortByText: 'Ordenar por'
              }"
              no-data-text="No se encontraron registros"
              loading-text="Cargando..."
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                'items-per-page-text': 'Registros por página',
                pageText: '{0}-{1} de {2}'
              }"
            >
              <template v-slot:top>
                <!-- v-container, v-col and v-row are just for decoration purposes. -->
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" md="6" sm="6" xs="12">
                      <v-text-field
                        autocomplete="off"
                        class="required"
                        dense
                        filled
                        color="blue-grey lighten-2"
                        v-model="filtro"
                        label="Nombre de la iniciativa, código..."
                        maxlength="200"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" sm="6" xs="12">
                      <v-select
                        v-model="estado"
                        :items="estados"
                        :loading="false"
                        dense
                        filled
                        label="Estado"
                        :no-data-text="'No existen estados registrados'"
                        menu-props="auto"
                        return-object
                      ></v-select>
                    </v-col>

                    <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                      <v-btn
                        class="ma-0 white--text d-none"
                        medium
                        color="light-blue darken-2"
                      >
                        <v-icon left>mdi-magnify</v-icon> Buscar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>

              <template v-slot:item="{ item }">
                <tr>
                  <td>CSSD-2022-0000{{ item.id }}</td>
                  <td>{{ item.nombreIniciativa }}</td>
                  <td>{{ item.tipo }}</td>
                  <td>{{ item.financiamiento }}</td>
                  <td>
                    <v-chip
                      class="ma-2 font-weight-medium"
                      label
                      :color="
                        item.estadosId === 1
                          ? 'blue-grey lighten-4'
                          : item.estadosId === 2
                          ? 'blue lighten-4'
                          : item.estadosId === 6
                          ? 'cyan lighten-4'
                          : item.estadosId === 3
                          ? 'teal lighten-4'
                          : item.estadosId === 4
                          ? 'deep-orange lighten-4'
                          : 'pink lighten-4'
                      "
                      :text-color="
                        item.estadosId === 1
                          ? 'blue-grey lighten-1'
                          : item.estadosId === 2
                          ? 'blue lighten-1'
                          : item.estadosId === 6
                          ? 'cyan darken-1'
                          : item.estadosId === 3
                          ? 'teal lighten-1'
                          : item.estadosId === 4
                          ? 'deep-orange lighten-1'
                          : 'pink darken-1'
                      "
                      small
                    >
                      {{ item.estado }}
                    </v-chip>
                  </td>
                  <td>
                    <!--<v-btn
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      color="blue-grey lighten-5"
                    >
                      <v-icon left>mdi-eye</v-icon> Detalle
                    </v-btn>-->

                    <v-btn
                      v-if="item.estadosId === 1"
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      color="blue-grey lighten-5"
                      @click="mostrarModalActualizar(item.id, item.modalidadId)"
                    >
                      <v-icon left>mdi-pencil</v-icon> Actualizar
                    </v-btn>

                    <!-- <v-btn
                      v-if="item.estadosId === 1 || item.estadosId === 4"
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize "
                      small
                      depressed
                      color="blue-grey lighten-5"
                      @click="prepararEnvio(item.id)"
                    >
                      <v-icon left>mdi-send</v-icon> Enviar
                    </v-btn>-->

                    <!-- <v-btn
                      v-if="item.estadosId === 3"
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      color="blue-grey lighten-5"
                      :to="{
                        name: 'negociacion-directa-proyectos',
                        params: {
                          negociacionId: $CryptoJS.AES.encrypt(
                            item.id.toString(),
                            'KEYADMINCNS2022'
                          ).toString(),
                        },
                      }"
                    >
                      <v-icon left>mdi-file</v-icon> Ver iniciativas
                    </v-btn> -->

                    <v-menu top :close-on-click="true">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="orange darken-2"
                          icon
                          fab
                          small
                          v-bind="attrs"
                          v-on="on"
                          v-if="item.asignacionActores === 0"
                        >
                          <v-icon color="orange darken-2">info</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item>
                          <v-list-item-title
                            >Debe agregar roles y actores para poder registrar
                            iniciativas.</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>

                    <v-btn
                      v-if="item.estadosId === 7"
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      color="blue-grey lighten-5"
                      @click="mostrardialogEnvio(item.nombre)"
                    >
                      <v-icon left>mdi-power</v-icon> Activar
                    </v-btn>

                    <v-btn
                      v-if="item.estadosId === 1"
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      @click="mostrarDialogFinalizarRegistro(item.id, 2)"
                      color="blue-grey lighten-5"
                    >
                      <v-icon left>mdi-source-commit-end</v-icon> Finalizar Registro
                    </v-btn>

                    <v-btn
                      v-if="item.estadosId === 3"
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      @click="mostrarDialogFinalizarRegistro(item.id, 4)"
                      color="blue-grey lighten-5"
                    >
                      <v-icon left>mdi-source-commit-end</v-icon> Finalizar Iniciativa
                    </v-btn>

                    <v-btn
                      v-if="item.estadosId === 2 || item.estadosId === 3"
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      color="blue-grey lighten-5"
                      :to="{
                        name: 'seguimiento-iniciativa',
                        params: {
                          iniciativaId: $CryptoJS.AES.encrypt(
                            item.id.toString(),
                            'KEYADMINCNS2022'
                          ).toString(),
                          negociacionId: $CryptoJS.AES.encrypt(
                            item.negociacionId.toString(),
                            'KEYADMINCNS2022'
                          ).toString(),
                        },
                      }"
                    >
                      <v-icon left>mdi-arrow-collapse-right</v-icon> Registro de avances
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <!--fin:: proyectos -->
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialogRegistro"
      max-width="1024px"
      transition="scroll-y-transition"
      persistent
      scrollable
    >
      <v-card tile>
        <v-card-title>
          {{ modalTitle }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="
              dialogRegistro = false;
              $refs.seccionDatosGenerales.resetItems(2)
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text  class="pl-0 pr-0 pt-0">
          <v-tabs
            v-model="tab"
            background-color="light-blue-502"
            centered
            dark
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              Datos Generales
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-tab>

            <v-tab href="#tab-2" :disabled="accion===1">
              Actividades
              <v-icon>mdi-plus</v-icon>
            </v-tab>

            <v-tab href="#tab-3" :disabled="accion===1">
              Presupuesto
              <v-icon>mdi-cash-100</v-icon>
            </v-tab>
            <!--<v-tab href="#tab-4" :disabled="accion===1">
              Vinculación a marcos de desarrollo
              <v-icon>mdi-map-outline</v-icon>
            </v-tab>-->

            <v-tab href="#tab-5" :disabled="accion===1">
              Vinculación a marcos de desarrollo
              <v-icon>mdi-map-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-6" :disabled="accion===1">
              Articulaciones
              <v-icon>mdi-share-variant</v-icon>
            </v-tab>

             <v-tab href="#tab-7" :disabled="accion===1">
              Datos de contactos
              <v-icon>mdi-map-outline</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item :value="'tab-1'">
              <v-card flat>
                <v-card-text>
                  <RegistroDirectaDatosGenerales
                  ref="seccionDatosGenerales" 
                  :negociacionId="negociacionId"
                  :iniciativaId="iniciativaId"
                  :accion="accion"
                  :nombreNegociacion="datosNegociacion.titulo"
                  tipoNegocion="directa"
                  @obtener-items="obtenerItems"
                  @actualizar-accion="actualizarAccion"
                  @actualizar-iniciativa="actualizarIniciativaId"
                />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-2'">
              <v-card flat>
                <v-card-text>
                  <RegistroDirectaActividades
                  ref="seccionActividades"
                  :negociacionId="negociacionId"
                  :iniciativaId="iniciativaId"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-3'">
              <v-card flat>
                <v-card-text>
                  <SeccionPresupuesto
                    ref="seccionPresupuesto"
                    :negociacionId="negociacionId"
                    :iniciativaId="iniciativaId"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- <v-tab-item :value="'tab-4'">
              <v-card flat>
                <v-card-text> 
                  <SeccionVinculacionDirecta :negociacionId="negociacionId"/>
                </v-card-text>
              </v-card>
            </v-tab-item>-->

            <v-tab-item :value="'tab-5'">
              <v-card flat>
                <v-card-text>
                  <SeccionVinculacionMarcosDesarrollo
                    ref="seccionVinculacionMarcosDesarrollo"
                    :negociacionId="negociacionId"
                    :iniciativaId="iniciativaId"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-6'">
              <v-card flat>
                <v-card-text>
                  <SeccionArticulacionesIniciativa
                    ref="SeccionArticulacionesIniciativa"
                    :negociacionId="negociacionId"
                    :iniciativaId="iniciativaId"
                    :modalidadId="modalidadId"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-7'">
              <v-card flat>
                <v-card-text>
                  <SeccionContactosDirecta
                    ref="seccionContactos"
                    :negociacionId="negociacionId"
                    :iniciativaId="iniciativaId"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--Dialog para marcar como finalizado el registro-->
    <v-dialog
      v-model="dialogFinalizarRegistro"
      max-width="800px"
      transition="scroll-y-transition"
      persistent
      scrollable
    >
      <v-card tile>
        <v-card-title>
          Actualizar iniciativa
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnActualizarEstadoLoading"
            @click="dialogFinalizarRegistro = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <p class="text-subtitle-1">
                {{ modalTextEstado }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogFinalizarRegistro = false"
            :disabled="btnActualizarEstadoLoading"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="actualizarEstadoIniciativa"
            :loading="btnActualizarEstadoLoading"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Fin dialog fin registro-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";

import RegistroDirectaDatosGenerales from "@/view/pages/cooperacion-sur-sur/externo/negociacion-directa/iniciativas/RegistroDirectaDatosGenerales.vue";
import RegistroDirectaActividades from "@/view/pages/cooperacion-sur-sur/externo/negociacion-directa/iniciativas/RegistroDirectaActividades.vue";
import SeccionPresupuesto from "@/view/pages/cooperacion-sur-sur/externo/negociacion-directa/iniciativas/SeccionPresupuesto.vue";
//import SeccionVinculacionDirecta from "@/view/pages/cooperacion-sur-sur/externo/negociacion-directa/iniciativas/SeccionVinculacionDirecta.vue";

import SeccionArticulacionesIniciativa from "@/view/pages/cooperacion-sur-sur/externo/negociacion-directa/iniciativas/SeccionArticulacionesIniciativa.vue";
import SeccionContactosDirecta from "@/view/pages/cooperacion-sur-sur/externo/negociacion-directa/iniciativas/SeccionContactosDirecta.vue";
import SeccionVinculacionMarcosDesarrollo from "@/view/pages/cooperacion-sur-sur/externo/negociacion-directa/iniciativas/SeccionVinculacionMarcosDesarrollo.vue";

import {
  OBTENER_INICIATIVAS,
  ACTUALIZAR_ESTADO_INICIATIVA
} from "@/core/services/store/negociaciones/iniciativadirecta.module";

import {
  // OBTENER_NEGOCIACIONES_DIRECTAS,
  // REGISTRAR_NEGOCIACION_DIRECTA,
  OBTENER_NEGOCIACION
  // ACTUALIZAR_NEGOCIACION_DIRECTA,
  // ENVIAR_NEGOCIACION
} from "@/core/services/store/negociaciones/negociaciondirecta.module";
export default {
  name: "AdminProyetosNegociacionDirecta",
  components: {
    DialogLoader,
    SnackAlert,
    RegistroDirectaDatosGenerales,
    RegistroDirectaActividades,
    SeccionPresupuesto,
    //  SeccionVinculacionDirecta,
    SeccionArticulacionesIniciativa,
    SeccionContactosDirecta,
    SeccionVinculacionMarcosDesarrollo
  },
  data() {
    return {
      tableLoading: false,
      dialogLoaderVisible: false,
      dialogLoaderText: "Obteniendo información...",
      items: [],
      filtro: "",
      dialogRegistro: false,
      modalTitle: "Registro de iniciativa",
      tab: null,
      estado: "",
      estados: [
        { text: "Todos", value: null },
        { value: 1, text: "Ingreso" },
        { value: 2, text: "En Ejecución" },
        { value: 3, text: "Finalizada" },
        { value: 4, text: "Cancelada" }
      ],
      negociacionId: 0,
      iniciativaId: 0,
      datosNegociacion: [],
      iniciativas: [],
      modalidadId: 0,
      modalTextEstado: "",
      nuevoEstadoId: 0,
      dialogFinalizarRegistro: false,
      btnActualizarEstadoLoading: false
    };
  },
  methods: {
    modalNuevo() {
      this.iniciativaId = 0;
      this.tab = "tab-1";
      if (this.$refs.seccionDatosGenerales) {
        this.$refs.seccionDatosGenerales.resetItems(2);
      }
      if (this.$refs.seccionActividades) {
        this.$refs.seccionActividades.resetItems();
      }
      if (this.$refs.seccionPresupuesto) {
        this.$refs.seccionPresupuesto.resetItems();
      }
      if (this.$refs.seccionVinculacionMarcosDesarrollo) {
        this.$refs.seccionVinculacionMarcosDesarrollo.resetItems(2);
      }
      if (this.$refs.seccionContactos) {
        this.$refs.seccionContactos.resetItems();
      }
      this.accion = 1;
      this.modalTitle = "Registrar iniciativa";
      this.btnRegistroText = "Registrar iniciativa";
      this.switchItemEstado = true;
      this.dialogRegistro = true;
    },
    //Obtener los datos de la negociación
    async obtenerDatosNegociacion(negociacionId) {
      this.datosNegociacion = [];
      await this.$store
        .dispatch(OBTENER_NEGOCIACION, negociacionId)
        .then(res => {
          //console.log(res);
          if (res.status === 200) {
            this.datosNegociacion = res.data;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
            );
          }
          this.dialogLoaderVisible = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
          );
          this.dialogLoaderVisible = false;
        });
    },

    //Obtener las iniciativas/proyectos de la negociacion
    async obtenerItems() {
      this.items = [];
      this.tableLoading = true;

      this.$store
        .dispatch(OBTENER_INICIATIVAS, {
          estadoId: 0,
          negociacionId: this.negociacionId
        })
        .then(res => {
          if (res.status === 200) {
            this.items = res.data;
            this.modalidadId = this.items.modalidadId;
            //this.tableLoading = true;
          }
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },

    //Obtener una iniciativa específica
    async mostrarModalActualizar(id, modalidadId) {
      this.accion = 2;
      this.modalidadId = modalidadId;
      this.tab = "tab-1";
      this.iniciativaId = id;

      this.resetDatosSecciones();

      this.dialogRegistro = true;
    },

    //Reset de los campos de las secciones
    resetDatosSecciones() {
      if (this.$refs.seccionDatosGenerales) {
        this.$refs.seccionDatosGenerales.resetItems(1);
        this.$refs.seccionDatosGenerales.obtenerInstituciones();
        this.$refs.seccionDatosGenerales.obtenerTiposIniciativa().then(() => {
          this.$refs.seccionDatosGenerales.obtenerEstadosNegociacion();
          this.$refs.seccionDatosGenerales.obtenerSectores();
          this.$refs.seccionDatosGenerales.obtenerModalidadesFinanciamiento();
          this.$refs.seccionDatosGenerales.obtenerInstitucionesNacionales();
          this.$refs.seccionDatosGenerales.obtenerRolesActores();
          this.$refs.seccionDatosGenerales.obtenerCoberturas();

          this.$refs.seccionDatosGenerales.obtenerSectoresSisCss();

          this.$refs.seccionDatosGenerales
            .obtenerEstadosIniciativa()
            .then(() => {
              if (this.accion === 2) {
                this.$refs.seccionDatosGenerales.obtenerIniciativa(
                  this.iniciativaId
                );
              }
            });
        });
      }

      if (this.$refs.seccionActividades) {
        this.$refs.seccionActividades.resetItems();
        this.$refs.seccionActividades.obtenerActividades(this.iniciativaId);
        /*this.$refs.seccionActividades.obtenerInstituciones().then(()=> {
          this.$refs.seccionActividades.obtenerTiposFuenteCooperante();
        
          this.$refs.seccionActividades.obtenerFuentesBilaterales().then(() => {
            
          });
        })*/
      }

      //Verificar que el componente esté cargado, si lo está, ejecutar los métodos
      if (this.$refs.seccionPresupuesto) {
        this.$refs.seccionPresupuesto.obtenerRoles().then(() => {
          this.$refs.seccionPresupuesto.obtenerPaises().then(() => {
            this.$refs.seccionPresupuesto.obtenerPeriodos();
            this.$refs.seccionPresupuesto.obtenerTiposAporte().then(() => {
              this.$refs.seccionPresupuesto.obtenerCostosGlobales(
                this.iniciativaId
              );
              //this.$refs.seccionPresupuesto.obtenerPresupuestoGeneral(this.iniciativaId);
              // this.$refs.seccionPresupuesto.obtenerCostosAnuales(this.iniciativaId);
            });
          });
        });
      }

      if (this.$refs.seccionVinculacionMarcosDesarrollo) {
        this.$refs.seccionVinculacionMarcosDesarrollo.resetItems(2);
        this.$refs.seccionVinculacionMarcosDesarrollo.obtenerCoberturaProyecto(
          this.iniciativaId
        );
      }

      if (this.$refs.SeccionArticulacionesIniciativa) {
        this.$refs.SeccionArticulacionesIniciativa.resetItems(2);
        this.$refs.SeccionArticulacionesIniciativa.obtenerDatosArticulacion(
          this.iniciativaId
        );
        this.$refs.SeccionArticulacionesIniciativa.obtenerMarcosCooperacion();

        this.$refs.SeccionArticulacionesIniciativa.obtenerMecanismosEvaluacion();
        this.$refs.SeccionArticulacionesIniciativa.obtenerOrganismosCooperacion();
        this.$refs.SeccionArticulacionesIniciativa.obtenerMecanismosCooperacion();
        this.$refs.SeccionArticulacionesIniciativa.obtenerOrigenesIniciativa();
      }

      if (this.$refs.seccionContactos) {
        this.$refs.seccionContactos.resetItems();
        this.$refs.seccionContactos.obtenerTiposContacto().then(() => {
          this.$refs.seccionContactos.obtenerCargos().then(() => {
            this.$refs.seccionContactos.obtenerContactos(this.iniciativaId);
          });
        });
      }
    },

    filtroEstado(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.estado.value) {
        return true;
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.estado.text;
    },

    //Actualizar la variable acción a 2 cuando se registren los datos iniciales para activar las demas pestañas del tab
    actualizarAccion() {
      this.accion = 2;
    },

    actualizarIniciativaId(id) {
      this.iniciativaId = id;
    },

    //Mostrar el dialog para
    mostrarDialogFinalizarRegistro(id, tipo) {
      //Si es "Finalizar Registro"
      if (tipo === 2) {
        this.modalTextEstado =
          "¿Está seguro(a) de marcar la iniciativa como 'Registro Finalizado'?";
      }
      if (tipo === 4) {
        this.modalTextEstado =
          "¿Está seguro(a) de marcar la iniciativa como 'Iniciativa Finalizada'?";
      }

      this.nuevoEstadoId = tipo;
      this.iniciativaId = id;
      this.dialogFinalizarRegistro = true;
    },

    async actualizarEstadoIniciativa() {
      this.btnActualizarEstadoLoading = true;

      await this.$store
        .dispatch(ACTUALIZAR_ESTADO_INICIATIVA, {
          id: this.iniciativaId,
          estadoId: this.nuevoEstadoId
        })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.dialogFinalizarRegistro = false;

            this.obtenerItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Error!",
              res.message
            );
          }
          this.btnActualizarEstadoLoading = false;
        })
        .catch(error => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnActualizarEstadoLoading = false;
        });
    }
  },
  created() {
    this.parameters = this.$route.params;
    let negId = this.$CryptoJS.AES.decrypt(
      this.parameters.negociacionId,
      "KEYADMINCNS2022"
    ).toString(this.CryptoJS.enc.Utf8);

    this.negociacionId = parseInt(negId);
    //console.log(this.negociacionId)
    this.obtenerDatosNegociacion(this.negociacionId);
    this.obtenerItems();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "/dashboard" },
      { title: "Negociaciones" },
      { title: "Directa" },
      { title: "Iniciativas" }
    ]);
  },

  computed: {
    headers() {
      return [
        {
          text: "Codigo",
          align: "start",
          sortable: false,
          value: "codigo"
        },
        {
          text: "Nombre de la iniciativa",
          align: "start",
          sortable: true,
          value: "nombreIniciativa"
        },
        {
          text: "Tipo",
          align: "start",
          sortable: true,
          value: "tipo"
        },
        {
          text: "Modalidad Financiamiento",
          align: "start",
          sortable: true,
          value: "financiamiento"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estado",
          filter: this.filtroEstado
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    }
  }
};
</script>
<style lang=""></style>
