<template>
<div>
    <div v-if="skeletonLoading">
        <v-row>
            <v-col cols="12" md="12">
                <v-skeleton-loader
                v-bind="attrs"
                min-height="600px"
                type="article, article, article, actions"
                >
                </v-skeleton-loader>
        </v-col>
        </v-row>
    </div>

    <div v-if="!skeletonLoading">
        <v-card class="mt-6" outlined>
            <v-card-text>
                <v-row>
                <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
                        <v-subheader class="text-h6 black--text bold--text">
                            Articulaciones
                        </v-subheader>
                    </v-col>
                </v-row>

                <v-row v-if="modalidadId!=3">
                    <v-col cols="12">
                        <v-alert
                            text
                            type="warning"
                            color="orange darken-1"
                            icon="mdi-alert"
                            >
                            Las articulaciones solo son requeridas para las negociaciones en modalidad Triangular!
                        </v-alert>
                    </v-col>
                </v-row>

                <v-form ref="form" v-on:submit.prevent="registrarItem" v-model="validForm" v-if="modalidadId===3">
                    <v-row  class="mt-6">

                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-select
                                dense
                                filled
                                v-model="datosItem.marcoCooperacionSIDICSSId"
                                :items="marcosCooperacion"
                                class="required"
                                color="blue-grey lighten-2"
                                label="Marco en el que se ampara la cooperación"
                                item-text="nombreMarco"
                                item-value="id"
                                menu-props="auto"
                                :rules="[
                                    modalidadId===3 ? selectRequired('marco en el que se ampara la cooperacion') : true,
                                ]"
                            >
                            </v-select>
                        </v-col>

                    </v-row>

                    <v-row v-if="modalidadId===3">

                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-select
                                dense
                                filled
                                v-model="datosItem.originaIniciativaId"
                                :items="origenesIniciativa"
                                class="required"
                                color="blue-grey lighten-2"
                                label="¿Cómo se origina la iniciativa?"
                                item-text="origen"
                                item-value="id"
                                menu-props="auto"
                                :rules="[
                                    modalidadId == 3 ? selectRequired('origen de la iniciativa') : true,
                                ]"
                            >
                            </v-select>
                        </v-col>

                    </v-row>


                    <v-row v-if="modalidadId===3">

                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-select
                                dense
                                filled
                                v-model="estableceProcedimientoSeleccionado"
                                :items="procedimientosItems"
                                class="required"
                                color="blue-grey lighten-2"
                                label="Indique si se han establecido procedimientos/órganos para la toma de decisiones consensuadas entre todos los socios"
                                item-text="text"
                                item-value="id"
                                menu-props="auto"
                                return-object
                                :rules="[
                                    modalidadId == 3 ? selectRequired('procedimientos') : true,
                                ]"
                            >
                            </v-select>
                        </v-col>

                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0" v-if="estableceProcedimientoSeleccionado.id === 1">
                            <v-textarea
                                dense
                                filled
                                v-model="datosItem.especificacionProcOrganos"
                                rows="3"
                                class="required"
                                color="blue-grey lighten-2"
                                label="Especifique los procedimientos/órganos"
                                :rules="[
                                    modalidadId == 3 && estableceProcedimientoSeleccionado.id === 1 ? required('procedimientos') : true,
                                    modalidadId == 3 && estableceProcedimientoSeleccionado.id === 1 ? maxLength('procedimientos', 1000) : true,
                                    modalidadId == 3 && estableceProcedimientoSeleccionado.id === 1 ? minLength('procedimientos', 10) : true
                                ]"
                            >
                            </v-textarea>
                        </v-col>

                    </v-row>

                    <v-row v-if="modalidadId===3">

                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-select
                                dense
                                filled
                                v-model="mecanismoSegSeleccionado"
                                :items="mecanismosSegItems"
                                class="required"
                                color="blue-grey lighten-2"
                                label="Indique si se han establecido mecanismos de seguimiento y evaluación"
                                item-text="text"
                                item-value="id"
                                menu-props="auto"
                                return-object
                                :rules="[
                                    modalidadId == 3 ? selectRequired('procedimientos') : true,
                                ]"
                            >
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row v-if="modalidadId===3">
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-select
                                dense
                                filled
                                v-model="datosItem.mecanismosEvaluacionSIDICSSId"
                                :items="mecanismosEvaluacion"
                                class="required"
                                color="blue-grey lighten-2"
                                label="Señale los mecanismos de evaluación que se han implementado"
                                item-text="mecanismo"
                                item-value="id"
                                menu-props="auto"
                                :rules="[
                                    modalidadId == 3 ?  selectRequired('mecanismo de evaluación') : true,
                                ]"
                            >
                            </v-select>
                        </v-col>
                    </v-row>


                    <v-row v-if="modalidadId===2" class="mt-4">
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-select
                                dense
                                filled
                                v-model="datosItem.organismoEsquemaCooperacionId"
                                :items="organismosCooperacion"
                                class="required"
                                color="blue-grey lighten-2"
                                label="Organismo, esquema intergubernamental o similar que acompaña esta cooperación"
                                item-text="nombre"
                                item-value="id"
                                menu-props="auto"
                                :rules="[
                                    modalidadId == 2 ? selectRequired('mecanismo de evaluación') : true,
                                ]"
                            >
                            </v-select>
                        </v-col>
                    </v-row>


                    <v-row v-if="modalidadId===2">
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-select
                                dense
                                filled
                                v-model="datosItem.mecanismoCooperacionSIDICSSId"
                                :items="mecanismosCooperacion"
                                class="required"
                                color="blue-grey lighten-2"
                                label="Indique el mecanismo de cooperación bajo el que tiene lugar la iniciativa"
                                item-text="mecanismo"
                                item-value="id"
                                menu-props="auto"
                                :rules="[
                                    modalidadId == 2 ? selectRequired('mecanismo de cooperación') : true,
                                ]"
                            >
                            </v-select>
                        </v-col>
                    </v-row>


                    

                    <v-row class="mt-4">
                        <v-col cols="12" md="12" sm="12">
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                class="mb-2 float-right"
                                type="submit"
                                :elevation="0"
                                :disabled="!validForm"
                                :loading="btnRegistroLoading"
                            >
                                Guardar información
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-form>
                
            </v-card-text>
        </v-card>
    </div>

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

</div>
</template>


<script>
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";

import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
import {OBTENER_ARTICULACION, REGISTRAR_ARTICULACION , ACTUALIZAR_ARTICULACION } from "@/core/services/store/negociaciones/iniciativas/articulaciones/articulacion.module"; 
import validations from "@/core/untils/validations.js";

export default {
    name: "SeccionArticulacionesIniciativa",
    props: ['iniciativaId', 'negociacionId', 'modalidadId'],
    components: {
        DialogLoader,
        SnackAlert
    },
    data() {
        return {
            accion: 1,
            skeletonLoading: false,
            validForm: false,
            btnRegistroLoading: false,
            datosItem: {
                id: 0,
                procedimientosOrganos: "",
                especificacionProcOrganos: "",
                mecanismosSegEva: "",
                marcoCooperacionSIDICSSId: 0,
                iniciativaId: this.iniciativaId,
                mecanismosEvaluacionSIDICSSId: 0,
                organismoEsquemaCooperacionId: 0,
                mecanismoCooperacionSIDICSSId:0,
                originaIniciativaId:0,
                estadosId: 1,
                usuarioCreacion: "admin",
                origenIniciativaId: 0
            },


            marcosCooperacion:[],
            estableceProcedimientoSeleccionado: {},
            procedimientosItems: [{id: 2, text: "No"}, {id: 1, text: "Si"}],
            mecanismoSegSeleccionado: {},
            mecanismosSegItems: [{id: 2, text: "No"}, {id: 1, text: "Si"}],
            mecanismosEvaluacion: [],
            organismosCooperacion: [],
            mecanismosCooperacion: [],
            origenesIniciativa: [],
            ...validations
        }
    },

    methods: {

        resetItems(tipo){
            if(tipo===1){
                this.skeletonLoading = true;
            }

            this.datosItem = {
                id: 0,
                procedimientosOrganos: "",
                especificacionProcOrganos: "",
                mecanismosSegEva: "",
                marcoCooperacionSIDICSSId: 0,
                iniciativaId: this.iniciativaId,
                mecanismosEvaluacionSIDICSSId: 0,
                organismoEsquemaCooperacionId: 0,
                mecanismoCooperacionSIDICSSId:0,
                originaIniciativaId:0,
                estadosId: 1,
                usuarioCreacion: "admin",
                origenIniciativaId: 0
            };
            this.estableceProcedimientoSeleccionado= {};
            this.mecanismoSegSeleccionado= {};
            this.skeletonLoading = false;
        },

        /*
        * Obtener los datos de articulacion
        */
        async obtenerDatosArticulacion(iniciativaId){
            //this.datosItem = [];
            await this.$store
                .dispatch(OBTENER_ARTICULACION, iniciativaId)
                .then(res => {
                    if (res.status === 200) {
                        this.datosItem = res.data;
                        if(this.datosItem.id) {
                            this.accion = 2;
                        } else {
                            this.accion = 1;
                        }

                        this.estableceProcedimientoSeleccionado = this.procedimientosItems.find(item => item.text === this.datosItem.procedimientosOrganos);

                        this.mecanismoSegSeleccionado = this.mecanismosSegItems.find(item => item.text === this.datosItem.mecanismosSegEva);
                    }
                })
                .catch(() => {
                    this.datosItem = [];
                    //this.skeletonLoading=false;
                });
        },

        /*
        * Obtener los marcos en que se basa la cooperacion
        */
        async obtenerMarcosCooperacion(){
            this.marcosCooperacion = [];
            await this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'MarcoCooperacionSIDICSS/all/1'})
                .then(res => {
                    if (res.status === 200) {
                        this.marcosCooperacion = res.data;
                    }
                })
                .catch(() => {
                    this.marcosCooperacion = [];
                    //this.skeletonLoading=false;
                });
        },


        /*
        * Obtener los mecanismos de evaluación
        */
        async obtenerMecanismosEvaluacion(){
            this.mecanismosEvaluacion = [];
            await this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'MecanismosEvaluacionSIDICSS/all/1'})
                .then(res => {
                    if (res.status === 200) {
                        this.mecanismosEvaluacion = res.data;
                    }
                })
                .catch(() => {
                    this.mecanismosEvaluacion = [];
                    //this.skeletonLoading=false;
                });
        },


        /*
        * Obtener los organismos de la cooperación
        */
        async obtenerOrganismosCooperacion(){
            this.organismosCooperacion = [];
            await this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'OrganismoEsquemaCooperacion/all/1'})
                .then(res => {
                    if (res.status === 200) {
                        this.organismosCooperacion = res.data;
                    }
                })
                .catch(() => {
                    this.organismosCooperacion = [];
                    //this.skeletonLoading=false;
                });
        },


        /*
        * Obtener los mecanismos de la cooperación
        */
        async obtenerMecanismosCooperacion(){
            this.mecanismosCooperacion = [];
            await this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'MecanismoCooperacionSIDICSS/all/1'})
                .then(res => {
                    if (res.status === 200) {
                        this.mecanismosCooperacion = res.data;
                    }
                })
                .catch(() => {
                    this.mecanismosCooperacion = [];
                    //this.skeletonLoading=false;
                });
        },


        /*
        * Obtener los mecanismos de la cooperación
        */
        async obtenerOrigenesIniciativa(){
            this.origenesIniciativa = [];
            await this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'OrigenIniciativa/all/1'})
                .then(res => {
                    if (res.status === 200) {
                        this.origenesIniciativa = res.data;
                    }
                })
                .catch(() => {
                    this.origenesIniciativa = [];
                    //this.skeletonLoading=false;
                });
        },


        

        



        async registrarItem(){
            if(this.modalidadId===2){
                this.datosItem.marcoCooperacionSIDICSSId = null;
                this.datosItem.originaIniciativaId = null;
                this.datosItem.especificacionProcOrganos = null;
                this.datosItem.mecanismosSegEva = null;
                this.datosItem.mecanismosEvaluacionSIDICSSId = null;
            }
            if(this.modalidadId ===3){
                this.datosItem.mecanismosSegEva = this.mecanismoSegSeleccionado.text;
                this.datosItem.organismoEsquemaCooperacionId = null;
                this.datosItem.mecanismoCooperacionSIDICSSId = null;
            }

            this.datosItem.procedimientosOrganos = this.estableceProcedimientoSeleccionado.text;


            this.btnRegistroLoading = true;

            if (this.accion === 1) {
                await this.$store
                .dispatch(REGISTRAR_ARTICULACION, this.datosItem)
                .then(res => {
                    //this.btnLoading = false;
                    //this.dialogLoaderVisible = false;
                    if (res.status === 200) {
                        this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                        //this.obtenerItems();
                        //this.dialogRegistro = false;
                        //this.resetForm();
                        //this.$emit('obtener-items');
                        //this.$emit('actualizar-accion');
                        this.accion = 2;
                        this.datosItem.id = res.data;
                    } else {
                        this.$refs.snackalert.SnackbarShow("warning","Alerta",res.message);
                    }
                    this.btnRegistroLoading = false;
                })
                .catch(() => {
                    this.$refs.snackalert.SnackbarShow("warning","Alerta",`Ha ocurrido un error durante el registro.`);
                });
            }
            //?Si action es 2 se realiza la actualizacion
            else if(this.accion === 2){
                this.$store
                .dispatch(ACTUALIZAR_ARTICULACION, {
                    datosItem: this.datosItem,
                    id: this.datosItem.id
                })
                .then(res => {
                    //this.btnLoading = false;
                    //this.dialogLoaderVisible = false;
                    this.btnRegistroLoading = false;
                    
                    if (res.status === 200) {
                        this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                        // this.$emit('obtener-items');
                        //this.iniciativaId = this.datosItem.id;
                        this.accion=2;
                        //this.obtenerItems();
                        //this.dialogRegistro = false;
                        //this.resetForm();
                    } else {
                        this.$refs.snackalert.SnackbarShow("warning","Alerta",res.message);
                    }
                })
                .catch(() => {
                    this.$refs.snackalert.SnackbarShow("warning","Alerta",`Ha ocurrido un error durante el registro.`);
                });

            }
        }

    },

    created() {
        this.resetItems(1);
        this.obtenerDatosArticulacion(this.iniciativaId);
        this.obtenerMarcosCooperacion();
        this.obtenerMecanismosEvaluacion();
        this.obtenerOrganismosCooperacion();
        this.obtenerMecanismosCooperacion();
        this.obtenerOrigenesIniciativa();
    }
    
}
</script>